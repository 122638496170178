<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div v-if="!isLoading">
      <div v-if="ok">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-overlay center :show="isLoadingVariouAccounting" rounded="sm">
              <b-card
                header="Dettagli Generali"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="book_date"
                        :name="beForm[rep].book_date.label"
                        :label="beForm[rep].book_date.label"
                        v-model="form[rep].book_date"
                        :rules="getRules('book_date')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        name="Movimento Vario"
                        vid="various_accounting"
                        label="Movimento Vario"
                        v-model="form.various_accounting_id"
                        :options="various_accountings_options"
                        @select="onSelectVariousAccounting"
                        @remove="onRemoveVariousAccounting"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        :rules="getRules('title')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Dati Generali"
                header-tag="header"
                class="mt-1"
                v-if="enableInsurer || enableSalesman || enableRegistry"
              >
                <b-card-text>
                  <b-row>
                    <div class="col-md-3" v-if="enableInsurer">
                      <base-select
                        :name="beForm[rep].insurer_id.label"
                        vid="insurer_id"
                        :label="beForm[rep].insurer_id.label"
                        :options="companies"
                        v-model="form[rep].insurer_id"
                        :rules="getRules('insurer_id', 'various_accounting')"
                      />
                      <!-- FIX: rules ad cazzum: su various_accounting? -->
                    </div>
                    <div class="form-group col-md-3" v-if="enableSalesman">
                      <base-select
                        :name="beForm[rep].broker.label"
                        vid="broker_id"
                        :label="beForm[rep].broker.label"
                        v-model="form.broker_id"
                        :options="broker_options"
                        :rules="{ required: true }"
                      />
                      <!-- FIX: rules ad cazzum: hard-coded? -->
                    </div>
                  </b-row>
                  <b-row v-if="enableRegistry">
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              name="Anagrafica"
                              label="Anagrafica"
                              v-model="form.inpt_label"
                              :readonly="true"
                              :rules="
                                getRules('registry_id', 'various_accounting')
                              "
                            />
                            <!-- FIX: rules ad cazzum: su various_accounting? -->
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                            <b-button
                              class="mt-4 btn-quick ml-1"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              @click="resetRegistryId"
                              title="Elimina Anagrafica"
                              ><b-icon icon="trash"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Casse Astratte"
                header-tag="header"
                class="mt-1"
                v-if="
                  enableEntryDetails &&
                  getFilteredTreasuries(entry_details, 'Y', 'Y', 'N').length > 0
                "
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(dtl, index) in getFilteredTreasuries(
                        entry_details,
                        'Y',
                        'Y',
                        'N'
                      )"
                      :key="index"
                    >
                      <base-currency
                        :name="dtl.text"
                        :vid="`detail-${dtl.text}`"
                        :label="`${dtl.text}`"
                        v-model="dtl.gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="rules(dtl.id)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Casse Di Sistema"
                header-tag="header"
                class="mt-1"
                v-if="
                  enableEntryDetails &&
                  getFilteredTreasuries(entry_details, 'Y', 'N', 'N').length > 0
                "
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(dtl, index) in getFilteredTreasuries(
                        entry_details,
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="index"
                    >
                      <base-currency
                        :name="dtl.text"
                        :vid="`detail-${dtl.text}`"
                        :label="`${dtl.text}`"
                        v-model="dtl.gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="rules(dtl.id)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Casse Non di Sistema"
                header-tag="header"
                class="mt-1"
                v-if="
                  enableEntryDetails &&
                  getFilteredTreasuries(entry_details, 'N', 'N', 'N').length > 0
                "
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(dtl, index) in getFilteredTreasuries(
                        entry_details,
                        'N',
                        'N',
                        'N'
                      )"
                      :key="index"
                    >
                      <base-currency
                        :name="dtl.text"
                        :vid="`detail-${dtl.text}`"
                        :label="`${dtl.text}`"
                        v-model="dtl.gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="rules(dtl.id)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <template #overlay>
                <div class="text-center">
                  <base-icon name="loading" width="35" height="35" />
                  <p id="cancel-label">Operazione in corso...</p>
                </div>
              </template>
            </b-overlay>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button @click="resetForm()" variant="lisaweb" size="sm"
                >Reset</b-button
              >
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import FormMixin from "@/mixins/FormMixin";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import { today } from "@/utils/dates";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData /* , toRelationString  */ } from "@/utils/transforms";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { getFilteredTreasuries } from "@/utils/accounting";

export default {
  mixins: [FormMixin /*, EntryDetailsMixin */, ShortcutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingVariouAccounting: false,
      enableInsurer: false,
      enableSalesman: false,
      enableRegistry: false,
      enableEntryDetails: false,
      variousAccountingRules: [],
      entry_details: [],
      repository: "book_entry",
      variousAccountingId: null,
      //entry_details and treasuries
      treasury_options: [],
      treasuries: [],
      entryDetails: [],
      //v-models and options
      inpt_label: "",
      quick_value: "",
      registry_data: null,
      various_accountings_options: [],
      companies: [],
      treasuries_config: [
        {
          header: "Casse astratte",
          type: "abstract",
          is_system: "Y",
          is_abstract: "Y",
        },
        {
          header: "Casse di sistema",
          type: "system",
          is_system: "Y",
          is_abstract: "N",
        },
        {
          header: "Casse non di sistema",
          type: "not_system",
          is_system: "N",
          is_abstract: "N",
        },
      ],
      broker_options: [],
      form: {
        inpt_label: null,
        various_accounting_id: null,
        registry_id: null,
        broker_id: null,
        book_entry: {
          insurance_policy_number: null,
          book_date: today(),
          sheet_date: today(),
          effective_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: "N",
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          various_accounting: {},
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
      },
    };
  },
  methods: {
    toInfoData,
    initDefaultFormValues() {
      this.form.book_entry.is_taxable = "N";
    },
    onSubmit() {
      this.form[this.rep].sheet_date = this.form[this.rep].book_date;

      // prepare registry and salesman for referrer...
      this.form.registry_id
        ? (this.form[this.rep].registry = { [this.form.registry_id]: {} })
        : null;
      this.form.broker_id
        ? (this.form[this.rep].broker = { [this.form.broker_id]: {} })
        : null;
      this.form[this.rep].various_accounting = {
        [this.variousAccountingId]: {},
      };
      this.form[this.rep].effective_date = this.form[this.rep].book_date;
      let gross = 0.0;
      for (const key in this.entry_details) {
        gross += this.entry_details[key].gross;
      }
      this.form[this.rep].gross = gross;
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          // console.debug(response);
          let data = response.data.data;
          const entryDetailsPromises = this.storeEntryDetails(data);
          Promise.all(entryDetailsPromises)
            .then(() => {
              this.onSuccess();
            })
            .catch((error) => {
              // console.debug("error in submit('entry_details')");
              this.onFailure(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          // console.debug("error in submit('book_entry')");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    storeEntryDetails(book_entry_data) {
      let storeEntryDetails = [];
      this.form[this.rep_entrdtl].book_entry_id = book_entry_data.id;
      this.form[this.rep_entrdtl].detail_date = book_entry_data.book_date;
      Object.values(this.entry_details).forEach((dtl) => {
        this.form[this.rep_entrdtl].gross = dtl.gross;
        this.form[this.rep_entrdtl].treasury_id = dtl.id;
        let trs = this.store(this.rep_entrdtl);
        storeEntryDetails.push(trs);
      });
      return storeEntryDetails;
    },
    onSuccess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Contabile Creato (V)`,
      });
      this.shortcut("module.CONT.MOVV", null, null, "filterVariousAccounting");
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    openQuickSearchRegistry() {
      this.form.registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label = null;
      this.form.registry_id = null;
    },
    handleRegistryModalInput(value) {
      this.form.registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    onSelectVariousAccounting(id) {
      this.isLoadingVariouAccounting = true;

      const Repo = RepositoryFactory.get("book_entry");
      let querystring = `various_accounting/${id}`;
      return Repo.create(querystring)
        .then((response) => {
          const data = response.data;
          this.variousAccountingRules = data.rules;
          this.disableEntryDetailSection();
          if (data.fields.treasuries) {
            this.entry_details = this.entryDetailsMapping(data);
            this.enableEntryDetailSection();
          }
          this.variousAccountingId = id;
          this.enableGeneralDataSections(data.fields);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoadingVariouAccounting = false;
        });
    },
    enableGeneralDataSections(data) {
      this.deleteGeneralDataVmodels();
      this.enableGeneralSection(data);
      this.beRules["various_accounting"] = {
        insurer_id: this.variousAccountingRules["insurer_id"],
        registry_id: this.variousAccountingRules["registry"],
        broker_id: this.variousAccountingRules["broker"],
      };
    },
    enableEntryDetailSection() {
      this.enableEntryDetails = true;
    },
    onRemoveVariousAccounting() {
      this.variousAccountingId = null;
      this.deleteGeneralDataVmodels();
      this.disableGeneralSection();
      this.enableEntryDetails = false;
      this.entry_details = [];
    },
    disableGeneralSection() {
      this.enableInsurer = false;
      this.enableSalesman = false;
      this.enableRegistry = false;
    },
    disableEntryDetailSection() {
      this.entry_details = [];
      this.enableEntryDetails = false;
    },
    enableGeneralSection(fields) {
      this.enableInsurer = fields.insurer_id ? true : false;
      this.enableSalesman = fields.broker ? true : false;
      this.enableRegistry = fields.registry ? true : false;
    },
    deleteGeneralDataVmodels() {
      this.form.broker_id = null;
      this.form.registry_id = null;
      this.form[this.rep].insurer_id = null;
    },
    entryDetailsMapping(data) {
      let sectors = this.getSectors();
      return data.fields.treasuries.map((treasury) => {
        return {
          option: treasury.value,
          id: treasury.id,
          text: `${treasury.title} (${treasury.code})`,
          gross: 0.0,
          treasury: {
            sector: {
              is_abstract: {
                value: sectors.find(
                  (sector) => sector.value === treasury.sector_id
                ).is_abstract,
              },
              is_system: {
                value: sectors.find(
                  (sector) => sector.value === treasury.sector_id
                ).is_system,
              },
              is_agency: {
                value: sectors.find(
                  (sector) => sector.value === treasury.sector_id
                ).is_agency,
              },
            },
          },
        };
      });
    },
    rules(treasury_id) {
      const rule = this.variousAccountingRules.treasuries.find(
        (tr) => tr.id === treasury_id
      );
      this.beRules["treasury"] = {
        gross: rule.gross,
      };
      return this.getRules("gross", "treasury");
    },
    getFilteredTreasuries,
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getTreasuries: "treasuries",
      getSalesmen: "salesmen",
      getSectors: "sectors",
    }),
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    entryDetailsCreateTotal() {
      return toLocaleCurrency(
        Object.keys(this.form.book_entries.entry_details)
          .map((key) => {
            return +this.form.book_entries.entry_details[key].amount || 0;
          })
          .reduce(function (sum, i) {
            return sum + i;
          }, 0)
      );
    },
  },
  beforeMount() {
    this.isLoading = true;
    let querystring = "perPage=0";
    const Repo = RepositoryFactory.get("various_accounting");
    return Repo.index(querystring)
      .then((response) => {
        const data = response.data.data.filter(
          (el) => el.is_system.value === "N"
        );
        const be = this.fetchCreateForm(this.repository);
        const ed = this.fetchCreateForm(this.rep_entrdtl);
        Promise.all([be, ed])
          .then(() => {
            this.various_accountings_options = data.map((el) => {
              return {
                value: el.id,
                text: el.title,
              };
            });
            this.isLoading = false;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.form = {};
            this.isLoading = false;
          });
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.form = {};
        this.isLoading = false;
      });
  },
  mounted() {
    this.companies = this.getInsurers();
    this.broker_options = this.getSalesmen();
    this.initDefaultFormValues();
  },
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseSelect,
    BaseInput,
    BaseCurrency,
    QuickSearchRegistryModal,
  },
};
</script>

<style scoped>
.info {
  text-align: center !important;
}
</style>
