var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("div", [
            _vm.ok
              ? _c(
                  "div",
                  [
                    _c("validation-observer", {
                      ref: "observer",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ invalid, handleSubmit }) {
                              return [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          header: "Dettagli Generali",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "book_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .book_date.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .book_date.label,
                                                      rules:
                                                        _vm.getRules(
                                                          "book_date"
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .book_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "book_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].book_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "sheet_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .sheet_date.label,
                                                      label:
                                                        "Data Foglio Quadratura",
                                                      rules:
                                                        _vm.getRules(
                                                          "sheet_date"
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .sheet_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "sheet_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].sheet_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .broker.label,
                                                      vid: "broker_id",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .broker.label,
                                                      options:
                                                        _vm.broker_options,
                                                      rules: { required: true },
                                                    },
                                                    model: {
                                                      value: _vm.form.broker_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "broker_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.broker_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-input", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .title.label,
                                                      vid: "title",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .title.label,
                                                      rules:
                                                        _vm.getRules("title"),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep].title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Provvigione del Produttore",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-6",
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.onChangeSalerProvTake()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.isSalerProvTake,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isSalerProvTake =
                                                            $$v
                                                        },
                                                        expression:
                                                          "isSalerProvTake",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          !_vm.isSalerProvTake
                                                            ? _vm.enable_saler_prov_text
                                                            : _vm.disable_saler_prov_text
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _vm.isSalerProvTake
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].saler_prov_take
                                                            .label,
                                                          vid: "saler_prov_take",
                                                          label:
                                                            "Importo provvigione",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules(
                                                              "saler_prov_take"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .saler_prov_take,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "saler_prov_take",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].saler_prov_take",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isSalerProvTake
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].is_taxable.label,
                                                          vid: "is_taxable",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .is_taxable.label,
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .is_taxable
                                                              .options,
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .is_taxable,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "is_taxable",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].is_taxable",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Importi",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-6",
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.onChangeEntryDetails()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.isEntryDetails,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isEntryDetails =
                                                            $$v
                                                        },
                                                        expression:
                                                          "isEntryDetails",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          !_vm.isEntryDetails
                                                            ? _vm.enable_entry_details_text
                                                            : _vm.disable_entry_details_text
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _vm.isEntryDetails
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].net.label,
                                                          vid: "net",
                                                          label: "Imponibile",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules("net"),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .net,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "net",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].net",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isEntryDetails
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].gross.label,
                                                          vid: "gross",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .gross.label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules: {
                                                            required: true,
                                                            compare_numbers: {
                                                              otherValues: [
                                                                "0",
                                                              ],
                                                              operator: "!=",
                                                            },
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .gross,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "gross",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].gross",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _c("entry-details", {
                                              ref: "edRef",
                                              attrs: {
                                                details: Object.values(
                                                  _vm.dtlscfg
                                                ),
                                                rules: _vm.getRules(
                                                  "gross",
                                                  "entry_detail"
                                                ),
                                                noName: "",
                                                canDelete: "",
                                                amountField: "gross",
                                                repository: _vm.repository,
                                                enableRemainder: "",
                                              },
                                              on: {
                                                select: function ($event) {
                                                  return _vm.selectEditEntryDetail(
                                                    _vm.form.book_entries
                                                      .entry_details,
                                                    ...arguments
                                                  )
                                                },
                                                unselect: function ($event) {
                                                  return _vm.unselectEditEntryDetail(
                                                    _vm.form.book_entries
                                                      .entry_details,
                                                    ...arguments
                                                  )
                                                },
                                                delete: function ($event) {
                                                  return _vm.deleteEditEntryDetail(
                                                    _vm.form.book_entries
                                                      .entry_details,
                                                    ...arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form,
                                                callback: function ($$v) {
                                                  _vm.form = $$v
                                                },
                                                expression: "form",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "py-2 mb-4" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled: invalid,
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.onSubmit
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Salva\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.back()
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-chevron-double-left", {
                                              attrs: { "font-scale": "0.9" },
                                            }),
                                            _vm._v("Torna indietro"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3419135644
                      ),
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }