<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div v-if="!isLoading">
      <div v-if="ok">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dettagli Generali" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="book_date"
                      :name="beForm[rep].book_date.label"
                      :label="beForm[rep].book_date.label"
                      v-model="form[rep].book_date"
                      :rules="getRules('book_date')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="sheet_date"
                      :name="beForm[rep].sheet_date.label"
                      label="Data Foglio Quadratura"
                      v-model="form[rep].sheet_date"
                      :rules="getRules('sheet_date')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-select
                      :name="beForm[rep].broker.label"
                      vid="broker_id"
                      :label="beForm[rep].broker.label"
                      v-model="form.broker_id"
                      :options="broker_options"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      :name="beForm[rep].title.label"
                      vid="title"
                      :label="beForm[rep].title.label"
                      v-model="form[rep].title"
                      :rules="getRules('title')"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              header="Provvigione del Produttore"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-6">
                    <b-form-checkbox
                      v-model="isSalerProvTake"
                      name="check-button"
                      switch
                      @change="onChangeSalerProvTake()"
                      >{{
                        !isSalerProvTake
                          ? enable_saler_prov_text
                          : disable_saler_prov_text
                      }}</b-form-checkbox
                    >
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3" v-if="isSalerProvTake">
                    <base-currency
                      :name="beForm[rep].saler_prov_take.label"
                      vid="saler_prov_take"
                      label="Importo provvigione"
                      v-model="form[rep].saler_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('saler_prov_take')"
                    />
                  </div>
                  <div class="form-group col-md-3" v-if="isSalerProvTake">
                    <base-select
                      :name="beForm[rep].is_taxable.label"
                      vid="is_taxable"
                      :label="beForm[rep].is_taxable.label"
                      v-model="form[rep].is_taxable"
                      :options="beForm[rep].is_taxable.options"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>

            <b-card header="Importi" header-tag="header" class="mt-1">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-6">
                    <b-form-checkbox
                      v-model="isEntryDetails"
                      name="check-button"
                      switch
                      @change="onChangeEntryDetails()"
                      >{{
                        !isEntryDetails
                          ? enable_entry_details_text
                          : disable_entry_details_text
                      }}</b-form-checkbox
                    >
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3" v-if="isEntryDetails">
                    <base-currency
                      :name="beForm[rep].net.label"
                      vid="net"
                      label="Imponibile"
                      v-model="form[rep].net"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('net')"
                    />
                  </div>
                  <div class="form-group col-md-3" v-if="isEntryDetails">
                    <base-currency
                      :name="beForm[rep].gross.label"
                      vid="gross"
                      :label="beForm[rep].gross.label"
                      v-model="form[rep].gross"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="{
                        required: true,
                        compare_numbers: { otherValues: ['0'], operator: '!=' },
                      }"
                    />
                  </div>
                </b-row>
                <!-- TODO check what is required to allow getRules('gross', 'entry_detail') -->
                <!-- :rules="getRules('gross', 'entry_detail')" -->
                <entry-details
                  ref="edRef"
                  :details="Object.values(dtlscfg)"
                  v-model="form"
                  :rules="getRules('gross', 'entry_detail')"
                  noName
                  canDelete
                  amountField="gross"
                  :repository="repository"
                  enableRemainder
                  @select="
                    selectEditEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @unselect="
                    unselectEditEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @delete="
                    deleteEditEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                ></entry-details>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseInput from "@/components/form/BaseInput";
import EntryDetails from "@/components/form/EntryDetails";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  mixins: [FormMixin, EntryDetailsMixin, ShortcutMixin],
  data() {
    return {
      isLoading: false,
      //Switch Flags
      isSalerProvTake: false,
      isEntryDetails: false,
      //Switch Texts
      disable_saler_prov_text: "Disabilita la provvigione del produttore",
      enable_saler_prov_text: "Abilita la provvigione del produttore",
      disable_entry_details_text: "Disabilita l'importo",
      enable_entry_details_text: "Abilita l'importo",
      repository: "book_entry",
      id: this.$route.params.id,
      item: this.$route.params.item,
      treasury_options: [],
      treasuries: [],
      onlyRealTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      entryDetails: [],
      broker_options: [],
      form: {
        broker_id: null,
        book_entry: {
          insurance_policy_number: null,
          book_date: moment().format("YYYY-MM-DD"),
          effective_date: null,
          sheet_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: null,
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          various_accounting: {
            2: {},
          },
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        book_entries: {
          entry_details: {},
        },
      },
    };
  },
  methods: {
    toLocaleCurrency,
    initDefaultFormValues() {
      // this.form.book_entry.is_taxable = "N";
      this.isSalerProvTake = this.form[this.rep].saler_prov_take ? true : false;
      this.isEntryDetails =
        this.form[this.rep].gross || this.form[this.rep].net ? true : false;
    },
    initDefaultValues() {
      // Produttori
      this.broker_options = this.getSalesmen();
      this.form.broker_id = this.item.brokers.length
        ? this.item.brokers[0].id
        : null;
      // enable treasuries
      // this.onlyRealTreasuries = true;
      this.editEntryDetailsCompToggle = true; // REVIEW: workaround per disegnare la forma di pagamento vuota
    },
    onSubmit() {
      /**
       * promise.all: salva book_entries (Dettagli Generali + Provvigioni Produttore) e entry_details
       *
       * NOTA: i vari check, li fa PREVENTIVAMENTE alla promise.all
       *
       */
      // checks
      // TODO: effettuare il controllo se le forme di pagamento sono effettivamente abilitate...(isEntryDetails???)
      // if (this.isEntryDetails && this.details.gross !== this.sumEntryDetails) {
      if (
        this.isEntryDetails &&
        this.form[this.rep].gross != this.entryDetailsEditTotal
      ) {
        this.$showSnackbar({
          preset: "error",
          text: `La somma delle forme di pagamento deve essere uguale al lordo`,
        });
        return;
      }
      const sbep = this.submitBookEntryPromise();
      const sedp = this.submitEntryDetailsPromise();
      this.isLoading = true;
      Promise.all([sbep, sedp])
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Movimento Contabile Modificato (II)`,
          });
          // this.refreshData();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
      // if (this.form[this.rep].gross != this.entryDetailsCreateTotal) {
      //   this.$showSnackbar({
      //     preset: "error",
      //     text: `Il lordo deve essere uguale al totale delle forme di pagamento`,
      //   });
      //   return;
      // }
      // this.form[this.rep].broker = { [this.form.broker_id]: {} };
      // this.form[this.rep].effective_date = this.form[this.rep].book_date;
      // this.isLoading = true;
      // this.store(this.repository)
      //   .then((response) => {
      //     // console.debug(response);
      //     let data = response.data.data;
      //     if (this.isEntryDetails) {
      //       const entryDetailsPromises = this.storeEntryDetails(data);
      //       Promise.all(entryDetailsPromises)
      //         .then(() => {
      //           this.onSuccess();
      //           this.isLoading = false;
      //         })
      //         .catch((error) => {
      //           // console.debug("error in submit('entry_details')");
      //           this.onFailure(error);
      //           this.isLoading = false;
      //         });
      //     }
      //     this.onSuccess();
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     // console.debug("error in submit('book_entry')");
      //     this.onFailure(error);
      //     this.isLoading = false;
      //   });
    },
    submitBookEntryPromise() {
      this.setFormValues();
      console.debug("Form: ", this.form[this.rep]);
      // Modifica il foglio cassa
      return this.update(this.repository, this.id);
    },
    submitEntryDetailsPromise() {
      // if (this.details.gross == this.sumEntryDetails) {
      // this.isLoading = true;
      let storedtls = [];
      // salvare con un entry_time incrementale i NewIndex
      let ut = moment().unix();
      for (const key in this.dtlscfg) {
        if (isNaN(parseInt(key))) {
          if (
            this.form.book_entries.entry_details[key].type &&
            this.form.book_entries.entry_details[key].amount
          ) {
            // sto creando, imposto entry_time
            this.$set(
              this.form[this.rep_entrdtl],
              "entry_time",
              moment(ut).format("x")
            );
            ut += 1;
            this.form[this.rep_entrdtl].book_entry_id = this.id;
            this.form[this.rep_entrdtl].gross =
              this.form.book_entries.entry_details[key].amount;
            this.form[this.rep_entrdtl].treasury_id =
              this.form.book_entries.entry_details[key].type;
            // CHECK non esiste this.details: uso this.form.book_entry.book_date???
            // this.form[this.rep_entrdtl].detail_date = this.details.book_date;
            this.form[this.rep_entrdtl].detail_date =
              this.form.book_entry.book_date;
            let sdtls = this.store(this.rep_entrdtl);
            storedtls.push(sdtls);
          }
        } else {
          this.form[this.rep_entrdtl].book_entry_id = this.id;
          this.form[this.rep_entrdtl].gross =
            this.form.book_entries.entry_details[key].amount;
          this.form[this.rep_entrdtl].treasury_id =
            this.form.book_entries.entry_details[key].type;
          // CHECK non esiste this.details: uso this.form.book_entry.book_date???
          // this.form[this.rep_entrdtl].detail_date = this.details.book_date;
          this.form[this.rep_entrdtl].detail_date =
            this.form.book_entry.book_date;
          let sdtls = this.update(this.rep_entrdtl, key);
          storedtls.push(sdtls);
        }
      }

      for (const key of this.deletableEntryDetailIds) {
        let sdtls = this.delete("entry_detail", key);
        storedtls.push(sdtls);
      }

      return Promise.all(storedtls);
      // .then(() => {
      //   this.$showSnackbar({
      //     preset: "success",
      //     text: `Modifiche Salvate`,
      //   });
      //   this.refreshData();
      // })
      // .catch((error) => {
      //   let errMsg = this.$getErrorMessage(error);
      //   this.$showSnackbar({
      //     preset: "error",
      //     text: `${errMsg}`,
      //   });
      //   this.isLoading = false;
      // });
      // } else {
      //   this.$showSnackbar({
      //     preset: "error",
      //     text: `La somma delle forme di pagamento deve essere uguale al lordo`,
      //   });
      // }
    },
    setFormValues() {
      this.form[this.rep].effective_date = this.form[this.rep].book_date;
      // Anagrafica
      this.form[this.rep].registry = this.form.registry
        ? { [this.form.registry]: {} }
        : {};
      // Produttore
      this.form[this.rep].broker = this.form.broker_id
        ? { [this.form.broker_id]: {} }
        : {};
      // Tipologia di movimento Contabile
      console.debug("Item: ", this.item);
      if (this.isVariousAccounting) {
        const book_entry_id = this.item.various_accountings[0].id;
        this.form[this.rep]["various_accounting"] = {
          [book_entry_id]: {
            insurance_risk_id:
              this.item.various_accountings[0].pivot.insurance_risk_id,
          },
        };
      }
      if (
        this.item.insurance_ancillaries &&
        this.item.insurance_ancillaries.length
      ) {
        const insurance_ancillary_id = this.item.insurance_ancillaries[0].id;
        this.form[this.rep]["insurance_ancillary"] = {
          [insurance_ancillary_id]: {},
        };
      }
    },
    onChangeSalerProvTake() {
      if (!this.isSalerProvTake) {
        this.form[this.rep].saler_prov_take = 0.0;
      }
    },
    onChangeEntryDetails() {
      if (!this.isEntryDetails) {
        this.deleteAll();
        this.form[this.rep].gross = 0.0;
        this.form[this.rep].net = 0.0;
      }
    },
    deleteAll() {
      for (let d of this.dtls.filter((dtl) => dtl.selected)) {
        this.deleteCreateEntryDetail(
          this.form.book_entries.entry_details,
          d.id
        );
      }
    },
    onSuccess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Contabile Creato (II)`,
      });
      this.shortcut("module.CONT.CONP", null, null, "filterVariousAccounting");
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    // toCurrency(value) {
    //   if (!value) value = "0.00001";
    //   return toLocaleCurrency(parseFloat(value));
    // },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      // getTreasuries: "treasuries",
      getExcludedTreasuries: "excludedTreasuries",
    }),
  },
  components: {
    BaseIcon,
    BaseSelect,
    BaseDatepicker,
    BaseCurrency,
    BaseInput,
    EntryDetails,
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    isVariousAccounting() {
      return this.item.various_accountings &&
        this.item.various_accountings.length
        ? true
        : false;
    },
    entryDetailsEditTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
  },
  beforeMount() {
    // this.treasury_options = this.getTreasuries()();
    this.broker_options = this.getSalesmen();
  },
  mounted() {
    // this.initCreateEntryDetail(this.form.book_entries.entry_details);
    // this.initDefaultFormValues();
  },
  created() {
    this.isLoading = true;
    // const be = this.fetchCreateForm(this.repository);
    const be = this.fetchEditForm(this.repository, this.id);
    const ed = this.fetchCreateForm(this.rep_entrdtl); // serve per ottenere le rules sul campo gross dei details

    Promise.all([be, ed]).then(() => {
      this.initDefaultFormValues();
      this.initDefaultValues();
      this.entryDetails = this.item.entry_details;
      this.changeEntryDetails(this.form.book_entries.entry_details);
      this.isLoading = false;
    });
    // oppure risparmiano la chiamata "ed" utilizzo la rule sul gross di "be", anche se non è la stesso campo...
    /* this.fetchEditForm(this.repository, this.id).then(() => {
      this.initDefaultFormValues();
      this.initDefaultValues();
      this.entryDetails = this.item.entry_details;
      this.changeEntryDetails(this.form.book_entries.entry_details);
      this.isLoading = false;
    }); */
  },
};
</script>
