var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("div", [
            _vm.ok
              ? _c(
                  "div",
                  [
                    _c("validation-observer", {
                      ref: "observer",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ invalid, handleSubmit }) {
                              return [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          header: "Dati Generali",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "b-button-toolbar",
                                                          [
                                                            _c(
                                                              "b-button-group",
                                                              [
                                                                _c(
                                                                  "base-input",
                                                                  {
                                                                    attrs: {
                                                                      name: "Anagrafica",
                                                                      label:
                                                                        "Anagrafica",
                                                                      readonly: true,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .inpt_label,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "inpt_label",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.inpt_label",
                                                                    },
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "mt-4 btn-quick",
                                                                    attrs: {
                                                                      size: "sm",
                                                                      text: "Button",
                                                                      variant:
                                                                        "lisaweb",
                                                                      title:
                                                                        "Ricerca Veloce",
                                                                      disabled:
                                                                        _vm.type ===
                                                                          "edit" &&
                                                                        (_vm.isImported ||
                                                                          _vm.isConsolidated ||
                                                                          _vm.hasBrokerStatement),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.openQuickSearchRegistry,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-icon",
                                                                      {
                                                                        attrs: {
                                                                          icon: "search",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.form
                                                                  .inpt_label
                                                                  ? _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick ml-1",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Elimina Anagrafica",
                                                                          disabled:
                                                                            _vm.type ===
                                                                              "edit" &&
                                                                            (_vm.isImported ||
                                                                              _vm.isConsolidated ||
                                                                              _vm.hasBrokerStatement),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.resetRegistryId,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "trash",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "registry_data col-md-3",
                                                  },
                                                  [
                                                    _vm.registry_data
                                                      ? _c("span", {
                                                          staticClass: "info",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              _vm.toInfoData(
                                                                _vm.registry_data,
                                                                "registry"
                                                              )
                                                            ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "quick-search-registry-modal",
                                                  {
                                                    ref: "quickSearchRegistry",
                                                    on: {
                                                      input:
                                                        _vm.handleRegistryModalInput,
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-input", {
                                                    attrs: {
                                                      vid: "insurance_policy_number",
                                                      name: _vm.beForm[_vm.rep]
                                                        .insurance_policy_number
                                                        .label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .insurance_policy_number
                                                          .label,
                                                      rules: _vm.getRules(
                                                        "insurance_policy_number"
                                                      ),
                                                      readonly:
                                                        !_vm.isVariousAccounting,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .insurance_policy_number,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "insurance_policy_number",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].insurance_policy_number",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "book_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .book_date.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .book_date.label,
                                                      rules:
                                                        _vm.getRules(
                                                          "book_date"
                                                        ),
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.isConsolidated ||
                                                          _vm.hasBrokerStatement),
                                                      readonly:
                                                        _vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.isConsolidated ||
                                                          _vm.hasBrokerStatement),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .book_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "book_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].book_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "sheet_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .sheet_date.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .sheet_date.label,
                                                      rules:
                                                        _vm.getRules(
                                                          "sheet_date"
                                                        ),
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.isConsolidated ||
                                                          _vm.hasBrokerStatement),
                                                      readonly:
                                                        _vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.isConsolidated ||
                                                          _vm.hasBrokerStatement),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .sheet_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "sheet_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].sheet_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "effective_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .effective_date.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .effective_date.label,
                                                      rules:
                                                        _vm.getRules(
                                                          "effective_date"
                                                        ),
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.isConsolidated ||
                                                          _vm.hasBrokerStatement ||
                                                          _vm.isInsuranceAncillaries),
                                                      readonly:
                                                        _vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.isConsolidated ||
                                                          _vm.hasBrokerStatement ||
                                                          _vm.isInsuranceAncillaries),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .effective_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "effective_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].effective_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .insurer_id.label,
                                                      vid: "insurer_id",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .insurer_id.label,
                                                      options: _vm.companies,
                                                      rules:
                                                        _vm.getRules(
                                                          "insurer_id"
                                                        ),
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        ((_vm.isImported &&
                                                          !!_vm.form[_vm.rep]
                                                            .insurer_id) ||
                                                          (!_vm.isImported &&
                                                            _vm.isInsuranceAncillaries)),
                                                    },
                                                    on: {
                                                      input: _vm.onInputInsurer,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .insurer_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "insurer_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].insurer_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: "Ramo",
                                                      vid: "branch",
                                                      label: "Ramo",
                                                      options:
                                                        _vm.risk_branches,
                                                      multiple: false,
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        ((_vm.isImported &&
                                                          !!_vm.risk_branch_id) ||
                                                          (!_vm.isImported &&
                                                            _vm.isInsuranceAncillaries)),
                                                    },
                                                    on: {
                                                      input: _vm.onInputBranch,
                                                    },
                                                    model: {
                                                      value: _vm.risk_branch_id,
                                                      callback: function ($$v) {
                                                        _vm.risk_branch_id = $$v
                                                      },
                                                      expression:
                                                        "risk_branch_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: "Prodotto",
                                                      vid: "product",
                                                      label: "Prodotto",
                                                      options: _vm.prdct,
                                                      rules: { required: true },
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        ((_vm.isImported &&
                                                          !!_vm.insurance_risk_id) ||
                                                          (!_vm.isImported &&
                                                            _vm.isInsuranceAncillaries)),
                                                    },
                                                    on: {
                                                      select:
                                                        _vm.onSelectProduct,
                                                      remove:
                                                        _vm.onDeleteProduct,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.insurance_risk_id,
                                                      callback: function ($$v) {
                                                        _vm.insurance_risk_id =
                                                          $$v
                                                      },
                                                      expression:
                                                        "insurance_risk_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      vid: "mandate_code_id",
                                                      name: _vm.beForm[_vm.rep]
                                                        .mandate_code_id.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .mandate_code_id
                                                          .label,
                                                      options:
                                                        _vm.mandate_code_options,
                                                      placeholder:
                                                        "Seleziona un codice mandato",
                                                      rules: { required: true },
                                                      disabled:
                                                        !_vm.form[_vm.rep]
                                                          .insurer_id ||
                                                        (_vm.type === "edit" &&
                                                          _vm.isImported &&
                                                          !!_vm.form[_vm.rep]
                                                            .mandate_code_id) ||
                                                        (!_vm.isImported &&
                                                          _vm.isInsuranceAncillaries),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .mandate_code_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "mandate_code_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].mandate_code_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-input", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .title.label,
                                                      vid: "title",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .title.label,
                                                      rules:
                                                        _vm.getRules("title"),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep].title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .broker.label,
                                                      vid: "broker",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .broker.label,
                                                      options:
                                                        _vm.broker_options,
                                                      rules: { required: true },
                                                      disabled:
                                                        _vm.isConsolidated ||
                                                        _vm.hasBrokerStatement,
                                                    },
                                                    model: {
                                                      value: _vm.form.broker,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "broker",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.broker",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: "book_tags",
                                                      label: "Codice Documento",
                                                      options:
                                                        _vm.book_tags_opt,
                                                      disabled:
                                                        _vm.type === "edit" &&
                                                        ((_vm.isImported &&
                                                          !!_vm.form
                                                            .book_tag) ||
                                                          (!_vm.isImported &&
                                                            _vm.isInsuranceAncillaries)),
                                                    },
                                                    model: {
                                                      value: _vm.form.book_tag,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "book_tag",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.book_tag",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Premio",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "switch",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickSwitchBookEntry()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  name: "check-button",
                                                  switch: "",
                                                  disabled:
                                                    !_vm.insurance_risk_id ||
                                                    _vm.bookEntryBreakdowns
                                                      .length == 0 ||
                                                    _vm.isLoadingBreakdowns,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onChangeSwitchBookEntry()
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.isBookEntryBreakdown,
                                                  callback: function ($$v) {
                                                    _vm.isBookEntryBreakdown =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isBookEntryBreakdown",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.isBookEntryBreakdown
                                                        ? "Disabilita Scorpori"
                                                        : "Abilita Scorpori"
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        !_vm.isBookEntryBreakdown
                                          ? _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].net.label,
                                                          vid: "net",
                                                          label: `${
                                                            _vm.agencyHasFee &&
                                                            _vm.insurerHasFee
                                                              ? _vm.getDictionary(
                                                                  "premio_net"
                                                                )
                                                              : _vm.getDictionary(
                                                                  "net"
                                                                )
                                                          }`,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules("net"),
                                                          readonly:
                                                            !_vm.insurance_risk_id,
                                                          disabled:
                                                            _vm.type ===
                                                              "edit" &&
                                                            (_vm.isImported ||
                                                              (!_vm.isImported &&
                                                                _vm.isInsuranceAncillaries)),
                                                        },
                                                        on: {
                                                          blur: _vm.onNetBlur,
                                                          keyup: _vm.onInputNet,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .net,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "net",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].net",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].tax.label,
                                                          vid: "tax",
                                                          label: `${
                                                            _vm.agencyHasFee &&
                                                            _vm.insurerHasFee
                                                              ? _vm.getDictionary(
                                                                  "premio_tax"
                                                                )
                                                              : _vm.getDictionary(
                                                                  "tax"
                                                                )
                                                          }`,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules("tax"),
                                                          readonly:
                                                            !_vm.insurance_risk_id,
                                                          disabled:
                                                            _vm.type ===
                                                              "edit" &&
                                                            (_vm.isImported ||
                                                              (!_vm.isImported &&
                                                                _vm.isInsuranceAncillaries)),
                                                        },
                                                        on: {
                                                          keyup: _vm.onInputTax,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .tax,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "tax",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].tax",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                      attrs: {
                                                        "data-test":
                                                          "fk-or-not",
                                                      },
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: `${
                                                            _vm.agencyHasFee &&
                                                            _vm.insurerHasFee
                                                              ? _vm.getDictionary(
                                                                  "premio_gross_fk"
                                                                )
                                                              : _vm.getDictionary(
                                                                  "premio_gross"
                                                                )
                                                          }`,
                                                          vid: "gross",
                                                          label: `${
                                                            _vm.agencyHasFee &&
                                                            _vm.insurerHasFee
                                                              ? _vm.getDictionary(
                                                                  "premio_gross"
                                                                )
                                                              : _vm.getDictionary(
                                                                  "gross"
                                                                )
                                                          }`,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          readonly:
                                                            !_vm.insurance_risk_id,
                                                          disabled:
                                                            _vm.type ===
                                                              "edit" &&
                                                            _vm.isImported,
                                                          rules:
                                                            _vm.getRules(
                                                              "grosgetRuless"
                                                            ),
                                                        },
                                                        on: {
                                                          blur: _vm.onInputGross,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .gross,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "gross",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].gross",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isBookEntryBreakdown
                                          ? _c("b-card-text", [
                                              _vm.bookEntryBreakdowns.length
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-overlay",
                                                        {
                                                          attrs: {
                                                            center: "",
                                                            show: _vm.isLoadingBreakdowns,
                                                            rounded: "sm",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "overlay",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "loading",
                                                                                width:
                                                                                  "35",
                                                                                height:
                                                                                  "35",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id: "cancel-label",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Operazione in corso..."
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "b-table",
                                                            {
                                                              ref: "bookEntryBreakdowns",
                                                              attrs: {
                                                                hover: "",
                                                                items:
                                                                  _vm.bookEntryBreakdowns,
                                                                fields:
                                                                  _vm.fields,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "cell(net)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-7",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-currency",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: _vm
                                                                                        .beForm[
                                                                                        _vm
                                                                                          .rep
                                                                                      ]
                                                                                        .net
                                                                                        .label,
                                                                                      vid: `net-${row.item.id}`,
                                                                                      label:
                                                                                        _vm
                                                                                          .beForm[
                                                                                          _vm
                                                                                            .rep
                                                                                        ]
                                                                                          .net
                                                                                          .label,
                                                                                      options:
                                                                                        {
                                                                                          currency:
                                                                                            "EUR",
                                                                                          locale:
                                                                                            "it-IT",
                                                                                          precision: 2,
                                                                                        },
                                                                                      readonly:
                                                                                        row
                                                                                          .item
                                                                                          .net
                                                                                          .is_net ===
                                                                                        "Y"
                                                                                          ? false
                                                                                          : true,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row
                                                                                          .item
                                                                                          .net
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row
                                                                                              .item
                                                                                              .net,
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row.item.net.value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(tax)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-7",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-currency",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: "Tasse",
                                                                                      vid: `tax-${row.item.id}`,
                                                                                      label:
                                                                                        "Tasse",
                                                                                      options:
                                                                                        {
                                                                                          currency:
                                                                                            "EUR",
                                                                                          locale:
                                                                                            "it-IT",
                                                                                          precision: 2,
                                                                                        },
                                                                                      readonly:
                                                                                        row
                                                                                          .item
                                                                                          .tax
                                                                                          .is_tax ===
                                                                                        "Y"
                                                                                          ? false
                                                                                          : true,
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row
                                                                                          .item
                                                                                          .tax
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row
                                                                                              .item
                                                                                              .tax,
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row.item.tax.value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(grossrow)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-7",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: "grossrow",
                                                                                      vid: `grossrow-${row.item.id}`,
                                                                                      label:
                                                                                        "Lordo Riga",
                                                                                      custom_type:
                                                                                        "currency",
                                                                                      value:
                                                                                        _vm.grossRowBookEntry(
                                                                                          row
                                                                                            .item
                                                                                            .tax
                                                                                            .value,
                                                                                          row
                                                                                            .item
                                                                                            .net
                                                                                            .value
                                                                                        ),
                                                                                      readonly: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "bottom-row",
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "Totale"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-7",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "net-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.bookEntryNetSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-7",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "tax-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.bookEntryTaxSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-7",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "gross-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.bookEntryGrossSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.agencyHasFee && _vm.insurerHasFee
                                          ? [
                                              _c("b-row", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c("base-currency", {
                                                      attrs: {
                                                        name: "fee_net",
                                                        vid: "fee_net",
                                                        label:
                                                          _vm.getDictionary(
                                                            "diritto_net"
                                                          ),
                                                        readonly:
                                                          !_vm.insurerHasFee,
                                                        disabled:
                                                          _vm.type === "edit" &&
                                                          _vm.isImported,
                                                        options: {
                                                          currency: "EUR",
                                                          locale: "it-IT",
                                                          precision: 2,
                                                        },
                                                      },
                                                      on: {
                                                        keyup:
                                                          _vm.onInputNetDiritti,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep]
                                                            .fee_net,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form[_vm.rep],
                                                            "fee_net",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[rep].fee_net",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c("base-currency", {
                                                      attrs: {
                                                        name: "fee_tax",
                                                        vid: "fee_tax",
                                                        label:
                                                          _vm.getDictionary(
                                                            "diritto_tax"
                                                          ),
                                                        readonly:
                                                          !_vm.insurerHasFee,
                                                        disabled:
                                                          _vm.type === "edit" &&
                                                          _vm.isImported,
                                                        options: {
                                                          currency: "EUR",
                                                          locale: "it-IT",
                                                          precision: 2,
                                                        },
                                                      },
                                                      on: {
                                                        keyup:
                                                          _vm.onInputTaxDiritti,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep]
                                                            .fee_tax,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form[_vm.rep],
                                                            "fee_tax",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[rep].fee_tax",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3",
                                                  },
                                                  [
                                                    _c("base-currency", {
                                                      attrs: {
                                                        name: "fee_gross",
                                                        vid: "fee_gross",
                                                        label:
                                                          _vm.getDictionary(
                                                            "diritto_gross"
                                                          ),
                                                        disabled:
                                                          _vm.type === "edit" &&
                                                          _vm.isImported,
                                                        options: {
                                                          currency: "EUR",
                                                          locale: "it-IT",
                                                          precision: 2,
                                                        },
                                                      },
                                                      on: {
                                                        blur: _vm.onInputGrossDiritti,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep]
                                                            .fee_gross,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form[_vm.rep],
                                                            "fee_gross",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[rep].fee_gross",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("b-row", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group col-md-3 offset-md-6",
                                                  },
                                                  [
                                                    _c("base-currency", {
                                                      attrs: {
                                                        name: _vm.beForm[
                                                          _vm.rep
                                                        ].gross.label,
                                                        vid: "gross",
                                                        label:
                                                          _vm.getDictionary(
                                                            "total_to_be_invoiced_gross"
                                                          ),
                                                        options: {
                                                          currency: "EUR",
                                                          locale: "it-IT",
                                                          precision: 2,
                                                        },
                                                        readonly: true,
                                                        rules:
                                                          _vm.getRules("gross"),
                                                      },
                                                      model: {
                                                        value: _vm.grandGross,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.grandGross = $$v
                                                        },
                                                        expression:
                                                          "grandGross",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Provvigioni di Agenzia",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _vm.type === "create"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "switch",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onClickSwitchAgency()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    attrs: {
                                                      name: "check-button",
                                                      switch: "",
                                                      disabled:
                                                        !_vm.isBookEntryBreakdown,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onChangeSwitchAgency()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.isAgencyBreakdown,
                                                      callback: function ($$v) {
                                                        _vm.isAgencyBreakdown =
                                                          $$v
                                                      },
                                                      expression:
                                                        "isAgencyBreakdown",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.isAgencyBreakdown
                                                            ? "Disabilita Scorpori"
                                                            : "Abilita Scorpori"
                                                        ) +
                                                        "\n              "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.isAgencyBreakdown
                                          ? _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].agency_prov_take
                                                            .label,
                                                          vid: "agency_prov_take",
                                                          label: `
                      ${_vm.beForm[_vm.rep].agency_prov_take.label} ${
                                                            _vm.isLoadingSpecifications
                                                              ? " (in caricamento...)"
                                                              : ""
                                                          }
                    `,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          disabled:
                                                            _vm.type ===
                                                              "edit" &&
                                                            (_vm.isImported ||
                                                              _vm.isConsolidated ||
                                                              _vm.hasBrokerStatement ||
                                                              _vm.hasBreakdowns),
                                                          rules:
                                                            _vm.getRules(
                                                              "agency_prov_take"
                                                            ),
                                                          readonly:
                                                            _vm.isLoadingSpecifications,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .agency_prov_take,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "agency_prov_take",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].agency_prov_take",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].agency_prov_purchase
                                                            .label,
                                                          vid: "agency_prov_purchase",
                                                          label: `
                      ${_vm.beForm[_vm.rep].agency_prov_purchase.label} ${
                                                            _vm.isLoadingSpecifications
                                                              ? " (in caricamento...)"
                                                              : ""
                                                          }
                    `,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          disabled:
                                                            _vm.type ===
                                                              "edit" &&
                                                            (_vm.isImported ||
                                                              _vm.isConsolidated ||
                                                              _vm.hasBrokerStatement ||
                                                              _vm.hasBreakdowns),
                                                          rules: _vm.getRules(
                                                            "agency_prov_purchase"
                                                          ),
                                                          readonly:
                                                            _vm.isLoadingSpecifications,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .agency_prov_purchase,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "agency_prov_purchase",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].agency_prov_purchase",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isAgencyBreakdown
                                          ? _c("b-card-text", [
                                              _vm.agencyProvBreakdowns.length
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-overlay",
                                                        {
                                                          attrs: {
                                                            center: "",
                                                            show: _vm.isLoadingBreakdowns,
                                                            rounded: "sm",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "overlay",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "loading",
                                                                                width:
                                                                                  "35",
                                                                                height:
                                                                                  "35",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id: "cancel-label",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Operazione in corso..."
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "b-table",
                                                            {
                                                              ref: "agencyBreakdowns",
                                                              attrs: {
                                                                hover: "",
                                                                items:
                                                                  _vm.agencyProvBreakdowns,
                                                                fields:
                                                                  _vm.agencyFields,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "cell(take)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-7",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-currency",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `agency_take-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      custom_type:
                                                                                        "currency",
                                                                                      options:
                                                                                        {
                                                                                          currency:
                                                                                            "EUR",
                                                                                          locale:
                                                                                            "it-IT",
                                                                                          precision: 2,
                                                                                        },
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row
                                                                                          .item
                                                                                          .take
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row
                                                                                              .item
                                                                                              .take,
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row.item.take.value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(purchase)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-7",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-currency",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `agency_purchase-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      options:
                                                                                        {
                                                                                          currency:
                                                                                            "EUR",
                                                                                          locale:
                                                                                            "it-IT",
                                                                                          precision: 2,
                                                                                        },
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row
                                                                                          .item
                                                                                          .purchase
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row
                                                                                              .item
                                                                                              .purchase,
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row.item.purchase.value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(gross)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-7",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: "agency_prov_purchase",
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      custom_type:
                                                                                        "currency",
                                                                                      value:
                                                                                        _vm.grossRow(
                                                                                          row
                                                                                            .item
                                                                                            .id
                                                                                        ),
                                                                                      rules:
                                                                                        _vm.getRules(
                                                                                          "agency_prov_purchase"
                                                                                        ),
                                                                                      readonly: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "bottom-row",
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "Totale"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-7",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-7",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "take-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.agencyTakeSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-7",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "purchase-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.agencyPurchaseSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Provvigioni del Produttore",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c("b-row", [
                                          _vm.type === "create"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        disabled:
                                                          !_vm.isBookEntryBreakdown,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.onChangeSwitchSaler()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.isSalerBreakdown,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isSalerBreakdown =
                                                            $$v
                                                        },
                                                        expression:
                                                          "isSalerBreakdown",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onClickSwitchSaler()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.isSalerBreakdown
                                                                  ? "Scorpori Produttore"
                                                                  : "Provv. Produttore"
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-md-3",
                                            },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    name: "check-button",
                                                    switch: "",
                                                    disabled:
                                                      (_vm.type === "create" &&
                                                        _vm.isSalerBreakdown) ||
                                                      (_vm.type === "edit" &&
                                                        (_vm.isImported ||
                                                          _vm.hasBreakdowns)),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.computeBrokerStatementMode,
                                                    callback: function ($$v) {
                                                      _vm.computeBrokerStatementMode =
                                                        $$v
                                                    },
                                                    expression:
                                                      "computeBrokerStatementMode",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  Calcola in fase rendicontale utilizzando il capitolato\n                  provvigionale\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        !_vm.isSalerBreakdown
                                          ? _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].saler_prov_take
                                                            .label,
                                                          vid: "saler_prov_take",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .saler_prov_take
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          disabled:
                                                            (_vm.type ===
                                                              "create" &&
                                                              _vm.computeBrokerStatementMode) ||
                                                            (_vm.type ===
                                                              "edit" &&
                                                              (_vm.isImported ||
                                                                _vm.isConsolidated ||
                                                                _vm.hasBrokerStatement ||
                                                                _vm.hasBreakdowns ||
                                                                // !insurerHasFee ||
                                                                _vm.computeBrokerStatementMode)),
                                                          rules:
                                                            _vm.getRules(
                                                              "saler_prov_take"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .saler_prov_take,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "saler_prov_take",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].saler_prov_take",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].saler_prov_purchase
                                                            .label,
                                                          vid: "saler_prov_purchase",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .saler_prov_purchase
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          disabled:
                                                            (_vm.type ===
                                                              "create" &&
                                                              _vm.computeBrokerStatementMode) ||
                                                            (_vm.type ===
                                                              "edit" &&
                                                              (_vm.isImported ||
                                                                _vm.isConsolidated ||
                                                                _vm.hasBrokerStatement ||
                                                                _vm.hasBreakdowns ||
                                                                // !insurerHasFee ||
                                                                _vm.computeBrokerStatementMode)),
                                                          rules: _vm.getRules(
                                                            "saler_prov_purchase"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .saler_prov_purchase,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "saler_prov_purchase",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].saler_prov_purchase",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.isSalerBreakdown
                                          ? _c("b-card-text", [
                                              _vm.salerProvBreakdowns.length
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-overlay",
                                                        {
                                                          attrs: {
                                                            center: "",
                                                            show: _vm.isLoadingBreakdowns,
                                                            rounded: "sm",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "overlay",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                name: "loading",
                                                                                width:
                                                                                  "35",
                                                                                height:
                                                                                  "35",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id: "cancel-label",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Operazione in corso..."
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "b-table",
                                                            {
                                                              ref: "salerProvBreakdowns",
                                                              attrs: {
                                                                hover: "",
                                                                items:
                                                                  _vm.salerProvBreakdowns,
                                                                fields:
                                                                  _vm.salerFields,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "cell(agencytake)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-18",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `agency_take-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      custom_type:
                                                                                        "currency",
                                                                                      value:
                                                                                        _vm.agencyTakeRow(
                                                                                          row
                                                                                            .item
                                                                                            .id
                                                                                        ),
                                                                                      readonly: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(take)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-18",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-currency",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `saler_take-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      options:
                                                                                        {
                                                                                          currency:
                                                                                            "EUR",
                                                                                          locale:
                                                                                            "it-IT",
                                                                                          precision: 2,
                                                                                        },
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row
                                                                                          .item
                                                                                          .take
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row
                                                                                              .item
                                                                                              .take,
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row.item.take.value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(agencypurchase)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-18",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `agency_purchase-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      custom_type:
                                                                                        "currency",
                                                                                      value:
                                                                                        _vm.agencyPurchaseRow(
                                                                                          row
                                                                                            .item
                                                                                            .id
                                                                                        ),
                                                                                      readonly: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(purchase)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-18",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-currency",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `saler_purchase-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      options:
                                                                                        {
                                                                                          currency:
                                                                                            "EUR",
                                                                                          locale:
                                                                                            "it-IT",
                                                                                          precision: 2,
                                                                                        },
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        row
                                                                                          .item
                                                                                          .purchase
                                                                                          .value,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            row
                                                                                              .item
                                                                                              .purchase,
                                                                                            "value",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "row.item.purchase.value",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                    {
                                                                      key: "cell(gross)",
                                                                      fn: function (
                                                                        row
                                                                      ) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-md-18",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "base-input",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      name: row
                                                                                        .field
                                                                                        .label,
                                                                                      vid: `total_row-${row.item.id}`,
                                                                                      label:
                                                                                        row
                                                                                          .field
                                                                                          .label,
                                                                                      custom_type:
                                                                                        "currency",
                                                                                      value:
                                                                                        _vm.grossRow(
                                                                                          row
                                                                                            .item
                                                                                            .id
                                                                                        ),
                                                                                      readonly: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _vm._v(" "),
                                                              _c(
                                                                "template",
                                                                {
                                                                  slot: "bottom-row",
                                                                },
                                                                [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      "Totale"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-18",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-18",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-18",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "take-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.salerTakeSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-18",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label"
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group col-md-18",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "base-label",
                                                                          {
                                                                            staticClass:
                                                                              "purchase-sum-label",
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  _vm.salerPurchaseSum,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("b-row", [
                                          (_vm.type === "create" &&
                                            _vm.agencyHasFee &&
                                            _vm.insurerHasFee) ||
                                          (_vm.type === "edit" &&
                                            _vm.agencyHasFee)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-currency", {
                                                    attrs: {
                                                      name: "saler_fee_take",
                                                      vid: "saler_fee_take",
                                                      label:
                                                        "Diritti di incasso produttore",
                                                      disabled:
                                                        !_vm.insurerHasFee ||
                                                        _vm.computeBrokerStatementMode,
                                                      options: {
                                                        currency: "EUR",
                                                        locale: "it-IT",
                                                        precision: 2,
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .saler_fee_take,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "saler_fee_take",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].saler_fee_take",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Forme di Pagamento",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c("b-card-text", [
                                          _vm.type === "edit" && !_vm.canEdit
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.hasTreasuryRelation
                                                    ? _c("b-table", {
                                                        attrs: {
                                                          striped: "",
                                                          hover: "",
                                                          items:
                                                            _vm.entryDetails,
                                                          fields:
                                                            _vm.entryDetailFields,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "cell(treasury_code)",
                                                              fn: function (
                                                                row
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        row.item
                                                                          ?.treasury
                                                                          ?.code
                                                                      ) +
                                                                      "\n                  "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "cell(treasury_title)",
                                                              fn: function (
                                                                row
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        row.item
                                                                          ?.treasury
                                                                          ?.title
                                                                      ) +
                                                                      "\n                  "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "cell(amount)",
                                                              fn: function (
                                                                row
                                                              ) {
                                                                return [
                                                                  _vm._v(
                                                                    "\n                    " +
                                                                      _vm._s(
                                                                        _vm.toLocaleCurrency(
                                                                          row
                                                                            .item
                                                                            .gross
                                                                        )
                                                                      ) +
                                                                      "\n                  "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.hasTreasuryRelation
                                                    ? _c("p", [
                                                        _vm._v(
                                                          "\n                  Non ci sono forme di pagamento disponibili\n                "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm.type === "create" ||
                                              (_vm.type === "edit" &&
                                                _vm.canEdit)
                                            ? _c(
                                                "div",
                                                [
                                                  _vm.type === "edit" &&
                                                  _vm.canEdit
                                                    ? [
                                                        _vm.item.gross < 0 &&
                                                        _vm.sumEntryDetails >
                                                          _vm.item.gross
                                                          ? _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "mb-2",
                                                                attrs: {
                                                                  type: "button",
                                                                  variant:
                                                                    "lisaweb",
                                                                  size: "sm",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onAcconto()
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Chiudi con acconto\n                  "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("entry-details", {
                                                    ref: "edRef",
                                                    attrs: {
                                                      details:
                                                        _vm.type === "create"
                                                          ? _vm.dtls
                                                          : Object.values(
                                                              _vm.dtlscfg
                                                            ),
                                                      rules:
                                                        _vm.type === "create"
                                                          ? _vm.getRules(
                                                              "gross"
                                                            )
                                                          : _vm.getRules(
                                                              "gross",
                                                              "entry_detail"
                                                            ),
                                                      repository:
                                                        _vm.repository,
                                                      amountField:
                                                        "byRemainderFunction",
                                                      remainderFunction:
                                                        _vm.byRemainderFunctionBookEntry,
                                                      noName:
                                                        _vm.type === "create"
                                                          ? false
                                                          : true,
                                                      enableRemainder: "",
                                                      canDelete: "",
                                                    },
                                                    on: {
                                                      select: function (
                                                        $event
                                                      ) {
                                                        _vm.type === "create"
                                                          ? _vm.selectCreateEntryDetail(
                                                              _vm.form
                                                                .book_entries
                                                                .entry_details,
                                                              ...arguments
                                                            )
                                                          : _vm.selectEditEntryDetail(
                                                              _vm.form
                                                                .book_entries
                                                                .entry_details,
                                                              ...arguments
                                                            )
                                                      },
                                                      unselect: function (
                                                        $event
                                                      ) {
                                                        _vm.type === "create"
                                                          ? _vm.unselectCreateEntryDetail(
                                                              _vm.form
                                                                .book_entries
                                                                .entry_details,
                                                              ...arguments
                                                            )
                                                          : _vm.unselectEditEntryDetail(
                                                              _vm.form
                                                                .book_entries
                                                                .entry_details,
                                                              ...arguments
                                                            )
                                                      },
                                                      delete: function (
                                                        $event
                                                      ) {
                                                        _vm.type === "create"
                                                          ? _vm.deleteCreateEntryDetail(
                                                              _vm.form
                                                                .book_entries
                                                                .entry_details,
                                                              ...arguments
                                                            )
                                                          : _vm.deleteEditEntryDetail(
                                                              _vm.form
                                                                .book_entries
                                                                .entry_details,
                                                              ...arguments
                                                            )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.form,
                                                      callback: function ($$v) {
                                                        _vm.form = $$v
                                                      },
                                                      expression: "form",
                                                    },
                                                  }),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "py-2 mb-4" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled: invalid,
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.onSubmit
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Salva\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.type === "create"
                                          ? _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.customResetForm()
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.back()
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-chevron-double-left", {
                                              attrs: { "font-scale": "0.9" },
                                            }),
                                            _vm._v("Torna indietro"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        92096584
                      ),
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }