var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      staticClass: "mt-2",
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("div", [
            _vm.ok
              ? _c(
                  "div",
                  [
                    _c("validation-observer", {
                      ref: "observer",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ invalid, handleSubmit }) {
                              return [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          header: "Dettagli Generali",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "book_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .book_date.label,
                                                      label: "Data effetto",
                                                      rules:
                                                        _vm.getRules(
                                                          "book_date"
                                                        ),
                                                      readonly: true,
                                                      disabled: true,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .book_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "book_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].book_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "sheet_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .sheet_date.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .sheet_date.label,
                                                      rules:
                                                        _vm.getRules(
                                                          "sheet_date"
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .sheet_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "sheet_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].sheet_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .broker.label,
                                                      vid: "broker",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .broker.label,
                                                      options:
                                                        _vm.broker_options,
                                                      rules: { required: true },
                                                    },
                                                    model: {
                                                      value: _vm.form.broker_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "broker_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.broker_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-input", {
                                                    attrs: {
                                                      name: "insurance_number",
                                                      vid: "insurance_number",
                                                      label: "Numero polizza",
                                                      readonly: true,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .insurance_policy_number,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "insurance_policy_number",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].insurance_policy_number",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: "Ramo",
                                                      vid: "branch",
                                                      label: "Ramo",
                                                      options:
                                                        _vm.risk_branches,
                                                      multiple: false,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value: _vm.risk_branch_id,
                                                      callback: function ($$v) {
                                                        _vm.risk_branch_id = $$v
                                                      },
                                                      expression:
                                                        "risk_branch_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-md-3" },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: "Prodotto",
                                                      vid: "product",
                                                      label: "Prodotto",
                                                      options: _vm.prdct,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.insurance_risk_id,
                                                      callback: function ($$v) {
                                                        _vm.insurance_risk_id =
                                                          $$v
                                                      },
                                                      expression:
                                                        "insurance_risk_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.form.collaborator_id
                                      ? _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Collaborazioni",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _vm.form.collaborator_id
                                                        ? _c("base-select", {
                                                            attrs: {
                                                              vid: "collaborator_id",
                                                              name: "Collaborazioni",
                                                              label:
                                                                "Collaborazioni",
                                                              options:
                                                                _vm.cooperators,
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .collaborator_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "collaborator_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.collaborator_id",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.form
                                                              .collaborator_id,
                                                          expression:
                                                            "form.collaborator_id",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "tipo_incasso",
                                                          name: "",
                                                          label: "Tipo Incasso",
                                                          options: [
                                                            {
                                                              text: "Incasso diretto",
                                                              value: 0,
                                                            },
                                                            {
                                                              text: "Tramite collaborazione",
                                                              value: 1,
                                                            },
                                                            {
                                                              text: "Nessuna ",
                                                              value: 2,
                                                            },
                                                          ],
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .collaboration_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "collaboration_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.collaboration_type",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Valore Contabile",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              !_vm.hasBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].net.label,
                                                          vid: "net",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .net.label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          readonly: "",
                                                          rules:
                                                            _vm.getRules("net"),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .net,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "net",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].net",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.hasBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].tax.label,
                                                          vid: "tax",
                                                          label: "Tasse",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          readonly: "",
                                                          rules:
                                                            _vm.getRules("tax"),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .tax,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "tax",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].tax",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.hasBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].gross.label,
                                                          vid: "gross",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .gross.label,
                                                          readonly: true,
                                                          custom_type:
                                                            "currency",
                                                          rules:
                                                            _vm.getRules(
                                                              "gross"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.insuranceAncillariesGrossPremiumSum,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.insuranceAncillariesGrossPremiumSum =
                                                              $$v
                                                          },
                                                          expression:
                                                            "insuranceAncillariesGrossPremiumSum",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _vm.hasBreakdown
                                              ? _c(
                                                  "b-table",
                                                  {
                                                    ref: "insuranceAncillariesBreakdowns",
                                                    attrs: {
                                                      hover: "",
                                                      items:
                                                        _vm
                                                          .insuranceAncillariesBreakdowns
                                                          .breakdowns,
                                                      fields: _vm.fields,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "cell(net)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-7",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-currency",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `net-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        options:
                                                                          {
                                                                            currency:
                                                                              "EUR",
                                                                            locale:
                                                                              "it-IT",
                                                                            precision: 2,
                                                                          },
                                                                        readonly: true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row
                                                                            .item
                                                                            .pivot
                                                                            .net,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .item
                                                                                .pivot,
                                                                              "net",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "row.item.pivot.net",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(tax)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-7",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-currency",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `tax-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        options:
                                                                          {
                                                                            currency:
                                                                              "EUR",
                                                                            locale:
                                                                              "it-IT",
                                                                            precision: 2,
                                                                          },
                                                                        readonly: true,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row
                                                                            .item
                                                                            .pivot
                                                                            .tax,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .item
                                                                                .pivot,
                                                                              "tax",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "row.item.pivot.tax",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(grossrow)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-7",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-input",
                                                                    {
                                                                      attrs: {
                                                                        name: "grossrow",
                                                                        vid: `grossrow-${row.item.id}`,
                                                                        label:
                                                                          "Lordo Riga",
                                                                        custom_type:
                                                                          "currency",
                                                                        value:
                                                                          _vm.grossRowInsuranceAncillaries(
                                                                            row
                                                                              .item
                                                                              .pivot
                                                                              .tax,
                                                                            row
                                                                              .item
                                                                              .pivot
                                                                              .net
                                                                          ),
                                                                        readonly: true,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _vm._v(" "),
                                                    _vm._v(" "),
                                                    _c(
                                                      "template",
                                                      { slot: "bottom-row" },
                                                      [
                                                        _c("td", [
                                                          _vm._v("Totale"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-7",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "net-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.insuranceAncillariesNetSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-7",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "tax-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.insuranceAncillariesTaxSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-7",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "gross-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.insuranceAncillariesGrossSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.agencyHasFee
                                      ? _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Diritti",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: "fee_net",
                                                          vid: "fee_net",
                                                          label: "Netto",
                                                          readonly: "",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .fee_net,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "fee_net",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].fee_net",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: "fee_tax",
                                                          vid: "fee_tax",
                                                          label: "Tasse",
                                                          readonly: "",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .fee_tax,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "fee_tax",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].fee_tax",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: "fee_gross",
                                                          vid: "fee_gross",
                                                          label: "Lordo",
                                                          readonly: "",
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .fee_gross,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "fee_gross",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].fee_gross",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "PROVVIGIONE AGENZIA",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _vm.hasBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            name: "check-button",
                                                            switch: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.hasAgencyBreakdown,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.hasAgencyBreakdown =
                                                                $$v
                                                            },
                                                            expression:
                                                              "hasAgencyBreakdown",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              !_vm.hasAgencyBreakdown
                                                                ? _vm.agency_section_breakdown_text
                                                                : _vm.agency_section_text
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              !_vm.hasBreakdown ||
                                              !_vm.hasAgencyBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].agency_prov_take
                                                            .label,
                                                          vid: "agency_prov_take",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .agency_prov_take
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules(
                                                              "agency_prov_take"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .agency_prov_take,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "agency_prov_take",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].agency_prov_take",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.hasBreakdown ||
                                              !_vm.hasAgencyBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].agency_prov_purchase
                                                            .label,
                                                          vid: "agency_prov_purchase",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .agency_prov_purchase
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules: _vm.getRules(
                                                            "agency_prov_purchase"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .agency_prov_purchase,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "agency_prov_purchase",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].agency_prov_purchase",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _vm.hasBreakdown &&
                                            _vm.hasAgencyBreakdown
                                              ? _c(
                                                  "b-table",
                                                  {
                                                    ref: "insuranceAncillariesAgencyBreakdowns",
                                                    attrs: {
                                                      hover: "",
                                                      items:
                                                        _vm
                                                          .insuranceAncillariesAgencyBreakdowns
                                                          .breakdowns,
                                                      fields: _vm.agencyFields,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "cell(take)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-10",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-currency",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `agency_prov_take-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        custom_type:
                                                                          "currency",
                                                                        options:
                                                                          {
                                                                            currency:
                                                                              "EUR",
                                                                            locale:
                                                                              "it-IT",
                                                                            precision: 2,
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row
                                                                            .item
                                                                            .pivot
                                                                            .agency_prov_take,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .item
                                                                                .pivot,
                                                                              "agency_prov_take",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "row.item.pivot.agency_prov_take",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(purchase)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-10",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-currency",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `agency_purchase-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        options:
                                                                          {
                                                                            currency:
                                                                              "EUR",
                                                                            locale:
                                                                              "it-IT",
                                                                            precision: 2,
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row
                                                                            .item
                                                                            .pivot
                                                                            .agency_prov_purchase,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .item
                                                                                .pivot,
                                                                              "agency_prov_purchase",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "row.item.pivot.agency_prov_purchase",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(gross)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-10",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-input",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: "gross_row",
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        custom_type:
                                                                          "currency",
                                                                        value:
                                                                          _vm.grossRowAncillaries(
                                                                            row
                                                                              .item
                                                                              .id
                                                                          ),
                                                                        rules:
                                                                          _vm.getRules(
                                                                            "agency_prov_purchase"
                                                                          ),
                                                                        readonly: true,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _vm._v(" "),
                                                    _vm._v(" "),
                                                    _c(
                                                      "template",
                                                      { slot: "bottom-row" },
                                                      [
                                                        _c("td", [
                                                          _vm._v("Totale"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [_c("base-label")],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "take-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.agencyTakeSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "purchase-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.agencyPurchaseSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "take-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.agencyProvTotal,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "PROVVIGIONE PRODUTTORE",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        disabled:
                                                          _vm.hasSalerBreakdown,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.computeBrokerStatementMode,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.computeBrokerStatementMode =
                                                            $$v
                                                        },
                                                        expression:
                                                          "computeBrokerStatementMode",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    Calcola in fase rendicontale utilizzando il capitolato\n                    provvigionale\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _vm.hasBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            name: "check-button",
                                                            switch: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.hasSalerBreakdown,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.hasSalerBreakdown =
                                                                $$v
                                                            },
                                                            expression:
                                                              "hasSalerBreakdown",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              !_vm.hasSalerBreakdown
                                                                ? _vm.saler_section_breakdown_text
                                                                : _vm.agency_section_text
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              !_vm.hasBreakdown ||
                                              !_vm.hasSalerBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].saler_prov_take
                                                            .label,
                                                          vid: "saler_prov_take",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .saler_prov_take
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules:
                                                            _vm.getRules(
                                                              "saler_prov_take"
                                                            ),
                                                          disabled:
                                                            _vm.computeBrokerStatementMode,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .saler_prov_take,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "saler_prov_take",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].saler_prov_take",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.hasBreakdown ||
                                              !_vm.hasSalerBreakdown
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-currency", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].saler_prov_purchase
                                                            .label,
                                                          vid: "saler_prov_purchase",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .saler_prov_purchase
                                                              .label,
                                                          options: {
                                                            currency: "EUR",
                                                            locale: "it-IT",
                                                            precision: 2,
                                                          },
                                                          rules: _vm.getRules(
                                                            "saler_prov_purchase"
                                                          ),
                                                          disabled:
                                                            _vm.computeBrokerStatementMode,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .saler_prov_purchase,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "saler_prov_purchase",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].saler_prov_purchase",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _vm._v(" "),
                                            _vm.hasBreakdown &&
                                            _vm.hasSalerBreakdown
                                              ? _c(
                                                  "b-table",
                                                  {
                                                    ref: "insuranceAncillariesSalerBreakdowns",
                                                    attrs: {
                                                      hover: "",
                                                      items:
                                                        _vm
                                                          .insuranceAncillariesSalerBreakdowns
                                                          .breakdowns,
                                                      fields: _vm.salerFields,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "cell(take)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-10",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-currency",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `saler_prov_take-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        options:
                                                                          {
                                                                            currency:
                                                                              "EUR",
                                                                            locale:
                                                                              "it-IT",
                                                                            precision: 2,
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row
                                                                            .item
                                                                            .pivot
                                                                            .saler_prov_take,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .item
                                                                                .pivot,
                                                                              "saler_prov_take",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "row.item.pivot.saler_prov_take",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(purchase)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-10",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-currency",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `saler_prov_purchase-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        options:
                                                                          {
                                                                            currency:
                                                                              "EUR",
                                                                            locale:
                                                                              "it-IT",
                                                                            precision: 2,
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row
                                                                            .item
                                                                            .pivot
                                                                            .saler_prov_purchase,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .item
                                                                                .pivot,
                                                                              "saler_prov_purchase",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "row.item.pivot.saler_prov_purchase",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(gross)",
                                                          fn: function (row) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-md-10",
                                                                },
                                                                [
                                                                  _c(
                                                                    "base-input",
                                                                    {
                                                                      attrs: {
                                                                        name: row
                                                                          .field
                                                                          .label,
                                                                        vid: `total_row-${row.item.id}`,
                                                                        label:
                                                                          row
                                                                            .field
                                                                            .label,
                                                                        custom_type:
                                                                          "currency",
                                                                        value:
                                                                          _vm.grossRowAncillaries(
                                                                            row
                                                                              .item
                                                                              .id
                                                                          ),
                                                                        readonly: true,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _vm._v(" "),
                                                    _vm._v(" "),
                                                    _c(
                                                      "template",
                                                      { slot: "bottom-row" },
                                                      [
                                                        _c("td", [
                                                          _vm._v("Totale"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [_c("base-label")],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "purchase-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.salerPurchaseSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "take-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.salerTakeSum,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-10",
                                                            },
                                                            [
                                                              _c("base-label", {
                                                                staticClass:
                                                                  "take-sum-label",
                                                                attrs: {
                                                                  value:
                                                                    _vm.salerProvTotal,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-currency", {
                                                    attrs: {
                                                      name: "fee_tax",
                                                      vid: "fee_tax",
                                                      label:
                                                        "Diritti di incasso produttore \n                    ",
                                                      options: {
                                                        currency: "EUR",
                                                        locale: "it-IT",
                                                        precision: 2,
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .saler_fee_take,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "saler_fee_take",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].saler_fee_take",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    !_vm.hasBreakdown &&
                                    _vm.hasInsurerParticipant
                                      ? _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header:
                                                "Coassicurazioni - Delega Nostra",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              _vm._l(
                                                _vm.insurerParticipants,
                                                function (
                                                  insurer_partipant,
                                                  index
                                                ) {
                                                  return _c(
                                                    "b-row",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                `Compagnia Coass: ${insurer_partipant.title}-${insurer_partipant.code}`
                                                              )
                                                            ),
                                                          ]),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Netto:\n                    " +
                                                                _vm._s(
                                                                  _vm.toLocaleCurrency(
                                                                    insurer_partipant
                                                                      .pivot.net
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Tasse:\n                    " +
                                                                _vm._s(
                                                                  _vm.toLocaleCurrency(
                                                                    insurer_partipant
                                                                      .pivot.tax
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                          _c("br"),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Lordo:\n                    " +
                                                                _vm._s(
                                                                  _vm.toLocaleCurrency(
                                                                    insurer_partipant
                                                                      .pivot
                                                                      .gross
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                          _c("br"),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "participant_prov_purchse",
                                                              vid: "participant_prov_purchse",
                                                              label:
                                                                "Provvigione Acquisto",
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                insurer_partipant
                                                                  .pivot
                                                                  .participant_prov_purchase,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    insurer_partipant.pivot,
                                                                    "participant_prov_purchase",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "\n                      insurer_partipant.pivot.participant_prov_purchase\n                    ",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-currency", {
                                                            attrs: {
                                                              name: "participant_prov_take",
                                                              vid: "participant_prov_take",
                                                              label:
                                                                "Provvigione Incasso",
                                                              options: {
                                                                currency: "EUR",
                                                                locale: "it-IT",
                                                                precision: 2,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                insurer_partipant
                                                                  .pivot
                                                                  .participant_prov_take,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    insurer_partipant.pivot,
                                                                    "participant_prov_take",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "insurer_partipant.pivot.participant_prov_take",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.form.collaboration_type !==
                                    _vm.BY_COLLABORATION
                                      ? _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Forme di pagamento",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c("b-card-text", [
                                              !_vm.hasBreakdown &&
                                              _vm.hasInsurerParticipant
                                                ? _c(
                                                    "div",
                                                    { staticClass: "switch" },
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          attrs: {
                                                            name: "check-button",
                                                            switch: "",
                                                            disabled:
                                                              _vm.hasBreakdown,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.storeInsureParticipantGross,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.storeInsureParticipantGross =
                                                                $$v
                                                            },
                                                            expression:
                                                              "storeInsureParticipantGross",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  Incassa anche premio coass\n                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "mt-4" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .gross < 0 &&
                                                            _vm.sumEntryDetails -
                                                              _vm.form[_vm.rep]
                                                                .gross !==
                                                              0,
                                                          expression:
                                                            "\n                    form[rep].gross < 0 &&\n                    sumEntryDetails - form[rep].gross !== 0\n                  ",
                                                        },
                                                      ],
                                                      staticClass: "mb-2",
                                                      attrs: {
                                                        type: "button",
                                                        variant: "lisaweb",
                                                        size: "sm",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onAcconto()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Chiudi con acconto\n                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("entry-details", {
                                                    ref: "edRef",
                                                    attrs: {
                                                      details: _vm.dtls,
                                                      rules:
                                                        _vm.getRules("gross"),
                                                      repository:
                                                        _vm.repository,
                                                      amountField:
                                                        _vm.storeInsureParticipantGross
                                                          ? "byRemainderFunction"
                                                          : "calculatedGross",
                                                      remainderFunction:
                                                        _vm.storeInsureParticipantGross
                                                          ? _vm.byRemainderFunctionInsurerParticipant
                                                          : 0,
                                                      enableRemainder: "",
                                                      canDelete: "",
                                                    },
                                                    on: {
                                                      select: function (
                                                        $event
                                                      ) {
                                                        return _vm.selectCreateEntryDetail(
                                                          _vm.form.book_entries
                                                            .entry_details,
                                                          ...arguments
                                                        )
                                                      },
                                                      unselect: function (
                                                        $event
                                                      ) {
                                                        return _vm.unselectCreateEntryDetail(
                                                          _vm.form.book_entries
                                                            .entry_details,
                                                          ...arguments
                                                        )
                                                      },
                                                      delete: function (
                                                        $event
                                                      ) {
                                                        return _vm.deleteCreateEntryDetail(
                                                          _vm.form.book_entries
                                                            .entry_details,
                                                          ...arguments
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.form,
                                                      callback: function ($$v) {
                                                        _vm.form = $$v
                                                      },
                                                      expression: "form",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "py-2 mb-4" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled: invalid,
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.onSubmit
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Incassa\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.back()
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-chevron-double-left", {
                                              attrs: { "font-scale": "0.9" },
                                            }),
                                            _vm._v("Torna indietro"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1818130693
                      ),
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }