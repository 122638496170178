var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("div", [
            _vm.ok
              ? _c(
                  "div",
                  [
                    _c("validation-observer", {
                      ref: "observer",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ invalid, handleSubmit }) {
                              return [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          header: "Dettagli Generali",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-datepicker", {
                                                    attrs: {
                                                      vid: "book_date",
                                                      name: _vm.beForm[_vm.rep]
                                                        .book_date.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .book_date.label,
                                                      rules:
                                                        _vm.getRules(
                                                          "book_date"
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep]
                                                          .book_date,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "book_date",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].book_date",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: `${
                                            _vm.source === "registries"
                                              ? "Acconto Cliente"
                                              : "Acconto Produttore"
                                          }`,
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-select", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .broker.label,
                                                      vid: "broker_id",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .broker.label,
                                                      options:
                                                        _vm.broker_options,
                                                      rules: { required: true },
                                                    },
                                                    model: {
                                                      value: _vm.form.broker_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "broker_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.broker_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.source === "registries"
                                              ? _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "b-button-toolbar",
                                                              [
                                                                _c(
                                                                  "b-button-group",
                                                                  [
                                                                    _c(
                                                                      "base-input",
                                                                      {
                                                                        attrs: {
                                                                          name: "Anagrafica",
                                                                          label:
                                                                            "Anagrafica",
                                                                          readonly: true,
                                                                          rules:
                                                                            {
                                                                              required: true,
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .form
                                                                              .inpt_label,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.form,
                                                                                "inpt_label",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "form.inpt_label",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Ricerca Veloce",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.openQuickSearchRegistry,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "search",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mt-4 btn-quick ml-1",
                                                                        attrs: {
                                                                          size: "sm",
                                                                          text: "Button",
                                                                          variant:
                                                                            "lisaweb",
                                                                          title:
                                                                            "Elimina Anagrafica",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.resetRegistryId,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "trash",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "registry_data col-md-3",
                                                      },
                                                      [
                                                        _vm.registry_data
                                                          ? _c("span", {
                                                              staticClass:
                                                                "info",
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.toInfoData(
                                                                      _vm.registry_data,
                                                                      "registry"
                                                                    )
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "quick-search-registry-modal",
                                                      {
                                                        ref: "quickSearchRegistry",
                                                        on: {
                                                          input:
                                                            _vm.handleRegistryModalInput,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mt-1",
                                        attrs: {
                                          header: "Importo e descrizione",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c("b-row", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-currency", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .gross.label,
                                                      vid: _vm.beForm[_vm.rep]
                                                        .gross.label,
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .gross.label,
                                                      options: {
                                                        currency: "EUR",
                                                        locale: "it-IT",
                                                        precision: 2,
                                                      },
                                                      rules:
                                                        _vm.getRules("tax"),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep].gross,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "gross",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].gross",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-md-3",
                                                },
                                                [
                                                  _c("base-input", {
                                                    attrs: {
                                                      name: _vm.beForm[_vm.rep]
                                                        .title.label,
                                                      vid: "title",
                                                      label:
                                                        _vm.beForm[_vm.rep]
                                                          .title.label,
                                                      rules:
                                                        _vm.getRules("title"),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[_vm.rep].title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form[_vm.rep],
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[rep].title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("entry-details", {
                                              ref: "edRef",
                                              attrs: {
                                                details: _vm.dtls,
                                                rules: _vm.getRules("gross"),
                                                repository: _vm.repository,
                                                amountField: "gross",
                                                enableRemainder: "",
                                                canDelete: "",
                                              },
                                              on: {
                                                select: function ($event) {
                                                  return _vm.selectCreateEntryDetail(
                                                    _vm.form.book_entries
                                                      .entry_details,
                                                    ...arguments
                                                  )
                                                },
                                                unselect: function ($event) {
                                                  return _vm.unselectCreateEntryDetail(
                                                    _vm.form.book_entries
                                                      .entry_details,
                                                    ...arguments
                                                  )
                                                },
                                                delete: function ($event) {
                                                  return _vm.deleteCreateEntryDetail(
                                                    _vm.form.book_entries
                                                      .entry_details,
                                                    ...arguments
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form,
                                                callback: function ($$v) {
                                                  _vm.form = $$v
                                                },
                                                expression: "form",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "py-2 mb-4" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled: invalid,
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.onSubmit
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Salva\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.customResetForm()
                                              },
                                            },
                                          },
                                          [_vm._v("Reset")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.back()
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-chevron-double-left", {
                                              attrs: { "font-scale": "0.9" },
                                            }),
                                            _vm._v("Torna indietro"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1981278307
                      ),
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }