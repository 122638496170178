var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.onAdd },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "number",
                                  type: "text",
                                  label: "Numero Polizza",
                                  placeholder: "Inserisci un numero polizza",
                                },
                                on: { input: _vm.onInputNumberOrRegistry },
                                model: {
                                  value: _vm.filter.byCustom.number.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byCustom.number,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byCustom.number.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Anagrafica",
                                            label: "Anagrafica",
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.form.inpt_label_registry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "inpt_label_registry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.inpt_label_registry",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistry,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.inpt_label_registry
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Anagrafica",
                                                },
                                                on: {
                                                  click: _vm.resetRegistryId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-modal", {
                            ref: "quickSearchRegistry",
                            on: { input: _vm.handleRegistryModalInput },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group col-md-3 quick-search-result",
                            },
                            [
                              _vm.registry_data
                                ? _c("span", {
                                    staticClass: "info",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.toInfoData(
                                          _vm.registry_data,
                                          "registry"
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "book_date",
                                label: "Data Registrazione Dal",
                                disabled: _vm.isFilterLoading,
                              },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "book_date",
                                label: "Data Registrazione al",
                                disabled: _vm.isFilterLoading,
                              },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "effective_date",
                                label: "Data Effetto Dal",
                              },
                              model: {
                                value: _vm.filter.byEffectiveness.from,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byEffectiveness,
                                    "from",
                                    $$v
                                  )
                                },
                                expression: "filter.byEffectiveness.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "effective_date",
                                label: "Data Effetto al",
                              },
                              model: {
                                value: _vm.filter.byEffectiveness.to,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byEffectiveness,
                                    "to",
                                    $$v
                                  )
                                },
                                expression: "filter.byEffectiveness.to",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "insurer_id",
                                label: "Compagnia",
                                options: _vm.companies,
                                taggable: true,
                                multiple: true,
                              },
                              on: { input: _vm.onInputInsurer },
                              model: {
                                value: _vm.filter.byInsurer.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byInsurer, "id", $$v)
                                },
                                expression: "filter.byInsurer.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Produttore",
                                label: "Produttore",
                                options: _vm.salesmen,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byBrokerEnhanced.salesmen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byBrokerEnhanced,
                                    "salesmen",
                                    $$v
                                  )
                                },
                                expression: "filter.byBrokerEnhanced.salesmen",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Ramo",
                                label: "Ramo",
                                options: _vm.risk_branches,
                                multiple: true,
                                taggable: true,
                                closeOnSelect: false,
                              },
                              on: { input: _vm.onInputBranch },
                              model: {
                                value: _vm.filter.byRiskBranch.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byRiskBranch, "id", $$v)
                                },
                                expression: "filter.byRiskBranch.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Prodotto",
                                label: "Prodotto",
                                options: _vm.products,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byInsuranceRisk.id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byInsuranceRisk,
                                    "id",
                                    $$v
                                  )
                                },
                                expression: "filter.byInsuranceRisk.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "code",
                                type: "text",
                                label: "Codice Gruppo",
                                placeholder: "Inserisci un codice Gruppo",
                              },
                              model: {
                                value: _vm.filter.byRegistryGroup.code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byRegistryGroup,
                                    "code",
                                    $$v
                                  )
                                },
                                expression: "filter.byRegistryGroup.code",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "broker_statement",
                                label: "Stato Rendicontale",
                                options: [
                                  { value: "!0", text: "Rendicontato" },
                                  { value: "!", text: "Non rendicontato" },
                                ],
                                taggable: false,
                                multiple: false,
                                closeOnSelect: true,
                              },
                              model: {
                                value: _vm.filter.byBrokerStatement.id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byBrokerStatement,
                                    "id",
                                    $$v
                                  )
                                },
                                expression: "filter.byBrokerStatement.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1-inner",
                              arg: "collapse-1-inner",
                            },
                          ],
                          staticClass: "mt-2 mb-4 filter-button-group",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v("\n          Altri Filtri\n        "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { id: "collapse-1-inner" } },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "cooperators",
                                    label: "Collaborazioni",
                                    options: _vm.cooperators,
                                    taggable: true,
                                    multiple: true,
                                    closeOnSelect: false,
                                  },
                                  model: {
                                    value:
                                      _vm.filter.byBrokerEnhanced.cooperators,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byBrokerEnhanced,
                                        "cooperators",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byBrokerEnhanced.cooperators",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "treasury_id",
                                    label: "Forme di Pagamento",
                                    options: _vm.treasuries,
                                  },
                                  model: {
                                    value: _vm.filter.byTreasury.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter.byTreasury, "id", $$v)
                                    },
                                    expression: "filter.byTreasury.id",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "book_tags",
                                    label: "Codice Documento",
                                    options: _vm.book_tags_opt,
                                  },
                                  model: {
                                    value: _vm.filter.byBookTag.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter.byBookTag, "id", $$v)
                                    },
                                    expression: "filter.byBookTag.id",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "note",
                                    type: "text",
                                    label: "Nota",
                                    placeholder: "Inserisci una nota",
                                  },
                                  model: {
                                    value: _vm.filter.byTask.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byTask,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byTask.description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "REPL",
                                    type: "text",
                                    label: "Targa",
                                    placeholder: "Inserisci una targa",
                                  },
                                  model: {
                                    value: _vm.filter.byInsurancePolicy.REPL,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byInsurancePolicy,
                                        "REPL",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byInsurancePolicy.REPL",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-datepicker", {
                                  attrs: {
                                    name: "imported_at",
                                    label: "Data Foglio Cassa",
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.imported_at,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "imported_at",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byAttribute.imported_at",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-datepicker", {
                                  attrs: {
                                    name: "sheet_date",
                                    label: "Data Importazione",
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.sheet_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "sheet_date",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byAttribute.sheet_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-row", [
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c("base-currency", {
                                    attrs: {
                                      name: "gross",
                                      label: "Premio lordo da",
                                      options: {
                                        currency: "EUR",
                                        locale: "it-IT",
                                      },
                                      placeholder: "Inserisci un premio lordo",
                                    },
                                    model: {
                                      value: _vm.filter.byCurrency.from,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byCurrency,
                                          "from",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byCurrency.from",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-6" },
                                [
                                  _c("base-currency", {
                                    attrs: {
                                      name: "gross",
                                      label: "Premio lordo a",
                                      options: {
                                        currency: "EUR",
                                        locale: "it-IT",
                                      },
                                      placeholder: "Inserisci un premio lordo",
                                    },
                                    model: {
                                      value: _vm.filter.byCurrency.to,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byCurrency,
                                          "to",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byCurrency.to",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-md-3" },
                              [
                                _c("base-currency", {
                                  attrs: {
                                    name: "net",
                                    label: "Premio netto da",
                                    options: {
                                      currency: "EUR",
                                      locale: "it-IT",
                                    },
                                    placeholder: "Inserisci un premio netto",
                                  },
                                  model: {
                                    value: _vm.filter.byNet.from,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter.byNet, "from", $$v)
                                    },
                                    expression: "filter.byNet.from",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-md-3" },
                              [
                                _c("base-currency", {
                                  attrs: {
                                    name: "net",
                                    label: "Premio netto a",
                                    options: {
                                      currency: "EUR",
                                      locale: "it-IT",
                                    },
                                    placeholder: "Inserisci un premio netto",
                                  },
                                  model: {
                                    value: _vm.filter.byNet.to,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter.byNet, "to", $$v)
                                    },
                                    expression: "filter.byNet.to",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.customFilters.length > 0,
                                  expression: "customFilters.length > 0",
                                },
                              ],
                            },
                            [
                              _c("p", [_vm._v("Attributi personalizzati")]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.customFilters,
                                function (element, index) {
                                  return _c(
                                    "b-row",
                                    { key: index },
                                    [
                                      _c("custom-filter", {
                                        attrs: { element: element },
                                        model: {
                                          value:
                                            _vm.filter.byAttribute[element.key],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filter.byAttribute,
                                              element.key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filter.byAttribute[element.key]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("export-options", {
            attrs: {
              exportUrl: _vm.exportUrl,
              repository: _vm.repository,
              resource: _vm.resource,
              tableRef: _vm.$refs[_vm.tableRef],
              filter: _vm.filter,
              options: [
                {
                  code: "EXPOBOEN",
                  label: null,
                  formats: ["csv", "pdf"],
                },
                {
                  code: "REGIFOCA",
                  label: null,
                  formats: ["csv", "pdf"],
                },
                // {
                //   code: 'INCACOMP2',
                //   label: null,
                //   formats: ['pdf'],
                // },
                {
                  code: "TOTAFOCA",
                  label: null,
                  formats: ["pdf", "csv"],
                },
                {
                  code: "FOCACOL",
                  label: null,
                  formats: ["pdf", "csv"],
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("book-entries", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterOn: {
                byRelations: [
                  "byBroker",
                  "byDocument",
                  "byTask",
                  "byRegistry",
                  "byInsurer",
                  "byInsuranceAncillary",
                  "byVariousAccounting",
                  "byBookTag",
                  "byInsurerAccounting",
                  "byBreakdown",
                  "byInsurerParticipant",
                  "byBrokerStatement",
                ],
              },
              filterName: _vm.filterName,
              hasChecksButtonGroup: false,
              onlyActions: ["infomodal", "destroy", "edit"],
              sortField: "book_date",
            },
            on: {
              edit: _vm.onEdit,
              destroy: _vm.onDestroy,
              open_note: _vm.openNoteModal,
              open_document: _vm.openDocumentModal,
            },
          }),
          _vm._v(" "),
          _c("note-detail-modal", { attrs: { items: _vm.notes } }),
          _vm._v(" "),
          _c("document-detail-modal", { attrs: { items: _vm.documents } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }