var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _vm.mode === "I" ? _c("book-entry-form") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "II" ? _c("salesmen-accounting") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "III" ? _c("advance-payment") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "IV" ? _c("insurance-ancillaries") : _vm._e(),
      _vm._v(" "),
      _vm.mode === "V" ? _c("various-accountings") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }