var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c("base-icon", {
                    attrs: { name: "loading", width: "35", height: "35" },
                  }),
                  _vm._v(" "),
                  _c("p", { attrs: { id: "cancel-label" } }, [
                    _vm._v("Operazione in corso..."),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      !_vm.isLoading
        ? _c("div", [
            _vm.ok
              ? _c(
                  "div",
                  [
                    _c("validation-observer", {
                      ref: "observer",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ invalid, handleSubmit }) {
                              return [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-overlay",
                                      {
                                        attrs: {
                                          center: "",
                                          show: _vm.isLoadingVariouAccounting,
                                          rounded: "sm",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "overlay",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c("base-icon", {
                                                        attrs: {
                                                          name: "loading",
                                                          width: "35",
                                                          height: "35",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "p",
                                                        {
                                                          attrs: {
                                                            id: "cancel-label",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Operazione in corso..."
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "mt-1",
                                            attrs: {
                                              header: "Dettagli Generali",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "book_date",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].book_date.label,
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .book_date.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "book_date"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .book_date,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "book_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].book_date",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          name: "Movimento Vario",
                                                          vid: "various_accounting",
                                                          label:
                                                            "Movimento Vario",
                                                          options:
                                                            _vm.various_accountings_options,
                                                          rules: {
                                                            required: true,
                                                          },
                                                        },
                                                        on: {
                                                          select:
                                                            _vm.onSelectVariousAccounting,
                                                          remove:
                                                            _vm.onRemoveVariousAccounting,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .various_accounting_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "various_accounting_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.various_accounting_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title.label,
                                                          vid: "title",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .title.label,
                                                          rules:
                                                            _vm.getRules(
                                                              "title"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.enableInsurer ||
                                        _vm.enableSalesman ||
                                        _vm.enableRegistry
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Dati Generali",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _vm.enableInsurer
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-select",
                                                                {
                                                                  attrs: {
                                                                    name: _vm
                                                                      .beForm[
                                                                      _vm.rep
                                                                    ].insurer_id
                                                                      .label,
                                                                    vid: "insurer_id",
                                                                    label:
                                                                      _vm
                                                                        .beForm[
                                                                        _vm.rep
                                                                      ]
                                                                        .insurer_id
                                                                        .label,
                                                                    options:
                                                                      _vm.companies,
                                                                    rules:
                                                                      _vm.getRules(
                                                                        "insurer_id",
                                                                        "various_accounting"
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form[
                                                                        _vm.rep
                                                                      ]
                                                                        .insurer_id,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form[
                                                                            _vm
                                                                              .rep
                                                                          ],
                                                                          "insurer_id",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form[rep].insurer_id",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.enableSalesman
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-select",
                                                                {
                                                                  attrs: {
                                                                    name: _vm
                                                                      .beForm[
                                                                      _vm.rep
                                                                    ].broker
                                                                      .label,
                                                                    vid: "broker_id",
                                                                    label:
                                                                      _vm
                                                                        .beForm[
                                                                        _vm.rep
                                                                      ].broker
                                                                        .label,
                                                                    options:
                                                                      _vm.broker_options,
                                                                    rules: {
                                                                      required: true,
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .broker_id,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.form,
                                                                          "broker_id",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form.broker_id",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                    _vm._v(" "),
                                                    _vm.enableRegistry
                                                      ? _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group col-md-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "b-button-toolbar",
                                                                      [
                                                                        _c(
                                                                          "b-button-group",
                                                                          [
                                                                            _c(
                                                                              "base-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    name: "Anagrafica",
                                                                                    label:
                                                                                      "Anagrafica",
                                                                                    readonly: true,
                                                                                    rules:
                                                                                      _vm.getRules(
                                                                                        "registry_id",
                                                                                        "various_accounting"
                                                                                      ),
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .inpt_label,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.form,
                                                                                          "inpt_label",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "form.inpt_label",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "b-button",
                                                                              {
                                                                                staticClass:
                                                                                  "mt-4 btn-quick",
                                                                                attrs:
                                                                                  {
                                                                                    size: "sm",
                                                                                    text: "Button",
                                                                                    variant:
                                                                                      "lisaweb",
                                                                                    title:
                                                                                      "Ricerca Veloce",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.openQuickSearchRegistry,
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "search",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "b-button",
                                                                              {
                                                                                staticClass:
                                                                                  "mt-4 btn-quick ml-1",
                                                                                attrs:
                                                                                  {
                                                                                    size: "sm",
                                                                                    text: "Button",
                                                                                    variant:
                                                                                      "lisaweb",
                                                                                    title:
                                                                                      "Elimina Anagrafica",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.resetRegistryId,
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "trash",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "registry_data col-md-3",
                                                              },
                                                              [
                                                                _vm.registry_data
                                                                  ? _c("span", {
                                                                      staticClass:
                                                                        "info",
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              _vm.toInfoData(
                                                                                _vm.registry_data,
                                                                                "registry"
                                                                              )
                                                                            ),
                                                                        },
                                                                    })
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "quick-search-registry-modal",
                                                              {
                                                                ref: "quickSearchRegistry",
                                                                on: {
                                                                  input:
                                                                    _vm.handleRegistryModalInput,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.enableEntryDetails &&
                                        _vm.getFilteredTreasuries(
                                          _vm.entry_details,
                                          "Y",
                                          "Y",
                                          "N"
                                        ).length > 0
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Casse Astratte",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      _vm._l(
                                                        _vm.getFilteredTreasuries(
                                                          _vm.entry_details,
                                                          "Y",
                                                          "Y",
                                                          "N"
                                                        ),
                                                        function (dtl, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-currency",
                                                                {
                                                                  attrs: {
                                                                    name: dtl.text,
                                                                    vid: `detail-${dtl.text}`,
                                                                    label: `${dtl.text}`,
                                                                    options: {
                                                                      currency:
                                                                        "EUR",
                                                                      locale:
                                                                        "it-IT",
                                                                      precision: 2,
                                                                    },
                                                                    rules:
                                                                      _vm.rules(
                                                                        dtl.id
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      dtl.gross,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          dtl,
                                                                          "gross",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dtl.gross",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.enableEntryDetails &&
                                        _vm.getFilteredTreasuries(
                                          _vm.entry_details,
                                          "Y",
                                          "N",
                                          "N"
                                        ).length > 0
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header: "Casse Di Sistema",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      _vm._l(
                                                        _vm.getFilteredTreasuries(
                                                          _vm.entry_details,
                                                          "Y",
                                                          "N",
                                                          "N"
                                                        ),
                                                        function (dtl, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-currency",
                                                                {
                                                                  attrs: {
                                                                    name: dtl.text,
                                                                    vid: `detail-${dtl.text}`,
                                                                    label: `${dtl.text}`,
                                                                    options: {
                                                                      currency:
                                                                        "EUR",
                                                                      locale:
                                                                        "it-IT",
                                                                      precision: 2,
                                                                    },
                                                                    rules:
                                                                      _vm.rules(
                                                                        dtl.id
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      dtl.gross,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          dtl,
                                                                          "gross",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dtl.gross",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.enableEntryDetails &&
                                        _vm.getFilteredTreasuries(
                                          _vm.entry_details,
                                          "N",
                                          "N",
                                          "N"
                                        ).length > 0
                                          ? _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header:
                                                    "Casse Non di Sistema",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      _vm._l(
                                                        _vm.getFilteredTreasuries(
                                                          _vm.entry_details,
                                                          "N",
                                                          "N",
                                                          "N"
                                                        ),
                                                        function (dtl, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: index,
                                                              staticClass:
                                                                "form-group col-md-3",
                                                            },
                                                            [
                                                              _c(
                                                                "base-currency",
                                                                {
                                                                  attrs: {
                                                                    name: dtl.text,
                                                                    vid: `detail-${dtl.text}`,
                                                                    label: `${dtl.text}`,
                                                                    options: {
                                                                      currency:
                                                                        "EUR",
                                                                      locale:
                                                                        "it-IT",
                                                                      precision: 2,
                                                                    },
                                                                    rules:
                                                                      _vm.rules(
                                                                        dtl.id
                                                                      ),
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      dtl.gross,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          dtl,
                                                                          "gross",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "dtl.gross",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "py-2 mb-4" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              type: "button",
                                              disabled: invalid,
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.onSubmit
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Salva\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetForm()
                                              },
                                            },
                                          },
                                          [_vm._v("Reset")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              variant: "lisaweb",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.back()
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-chevron-double-left", {
                                              attrs: { "font-scale": "0.9" },
                                            }),
                                            _vm._v("Torna indietro"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1470182283
                      ),
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    "),
                ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }