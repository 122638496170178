<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div v-if="!isLoading">
      <div v-if="ok">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dettagli Generali" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="book_date"
                      :name="beForm[rep].book_date.label"
                      :label="beForm[rep].book_date.label"
                      v-model="form[rep].book_date"
                      :rules="getRules('book_date')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-select
                      :name="beForm[rep].broker.label"
                      vid="broker_id"
                      :label="beForm[rep].broker.label"
                      v-model="form.broker_id"
                      :options="broker_options"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      :name="beForm[rep].title.label"
                      vid="title"
                      :label="beForm[rep].title.label"
                      v-model="form[rep].title"
                      :rules="getRules('title')"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              header="Provvigione del Produttore"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-6">
                    <b-form-checkbox
                      v-model="isSalerProvTake"
                      name="check-button"
                      switch
                      @change="onChangeSalerProvTake()"
                      >{{
                        !isSalerProvTake
                          ? enable_saler_prov_text
                          : disable_saler_prov_text
                      }}</b-form-checkbox
                    >
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-6" v-if="isSalerProvTake">
                    <base-currency
                      :name="beForm[rep].saler_prov_take.label"
                      vid="saler_prov_take"
                      label="Importo provvigione"
                      v-model="form[rep].saler_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('saler_prov_take')"
                    />
                  </div>
                  <div class="form-group col-md-3" v-if="isSalerProvTake">
                    <base-select
                      :name="beForm[rep].is_taxable.label"
                      vid="is_taxable"
                      :label="beForm[rep].is_taxable.label"
                      v-model="form[rep].is_taxable"
                      :options="beForm[rep].is_taxable.options"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>

            <b-card header="Importi" header-tag="header" class="mt-1">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <b-form-checkbox
                      v-model="isEntryDetails"
                      name="check-button"
                      switch
                      @change="onChangeEntryDetails()"
                      >{{
                        !isEntryDetails
                          ? enable_entry_details_text
                          : disable_entry_details_text
                      }}</b-form-checkbox
                    >
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3" v-if="isEntryDetails">
                    <base-currency
                      :name="beForm[rep].net.label"
                      vid="net"
                      label="Imponibile"
                      v-model="form[rep].net"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('net')"
                    />
                  </div>
                  <div class="form-group col-md-3" v-if="isEntryDetails">
                    <base-currency
                      :name="beForm[rep].gross.label"
                      vid="gross"
                      :label="beForm[rep].gross.label"
                      v-model="form[rep].gross"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="{
                        required: true,
                        compare_numbers: { otherValues: ['0'], operator: '!=' },
                      }"
                    />
                  </div>
                </b-row>
                <entry-details
                  ref="edRef"
                  :details="dtls"
                  v-model="form"
                  :rules="getRules('gross')"
                  :repository="repository"
                  amountField="gross"
                  enableRemainder
                  canDelete
                  @select="
                    selectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @unselect="
                    unselectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @delete="
                    deleteCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                ></entry-details>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button @click="customResetForm()" variant="lisaweb" size="sm"
                >Reset</b-button
              >
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseInput from "@/components/form/BaseInput";
import EntryDetails from "@/components/form/EntryDetails";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  mixins: [FormMixin, EntryDetailsMixin, ShortcutMixin],
  data() {
    return {
      isLoading: false,
      //Switch Flags
      isSalerProvTake: false,
      isEntryDetails: false,
      //Switch Texts
      // #925
      disable_saler_prov_text: "Disabilita la provvigione del produttore",
      enable_saler_prov_text: "Abilita la provvigione del produttore",
      disable_entry_details_text: "Disabilita l'importo",
      enable_entry_details_text: "Abilita l'importo",
      repository: "book_entry",
      treasury_options: [],
      onlyRealTreasuries: true,
      skipAgencyTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      treasuries: [],
      entryDetails: [],
      broker_options: [],
      form: {
        broker_id: null,
        book_entry: {
          insurance_policy_number: null,
          book_date: moment().format("YYYY-MM-DD"),
          effective_date: null,
          sheet_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: null,
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          various_accounting: {
            2: {},
          },
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        book_entries: {
          entry_details: {},
        },
      },
    };
  },
  methods: {
    toLocaleCurrency,
    initDefaultFormValues() {
      this.form[this.rep].is_taxable = "N";
      // #925
      this.isEntryDetails =
        this.form[this.rep].gross || this.form[this.rep].net ? true : false;
    },
    onSubmit() {
      // #925: effettuare il controllo se le forme di pagamento sono effettivamente abilitate...(isEntryDetails???)
      if (
        this.isEntryDetails &&
        this.form[this.rep].gross != this.entryDetailsCreateTotal
      ) {
        this.$showSnackbar({
          preset: "error",
          text: `Il lordo deve essere uguale al totale delle forme di pagamento`,
        });
        return;
      }
      this.setFormValues();
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          let data = response.data.data;
          if (this.isEntryDetails) {
            const entryDetailsPromises = this.storeEntryDetails(data);
            Promise.all(entryDetailsPromises)
              .then(() => {
                this.onSuccess();
                this.isLoading = false;
              })
              .catch((error) => {
                this.onFailure(error);
                this.isLoading = false;
              });
          }
          this.onSuccess();
          this.isLoading = false;
        })
        .catch((error) => {
          this.onFailure(error);
          this.isLoading = false;
        });
    },
    setFormValues() {
      this.form[this.rep].sheet_date = this.form[this.rep].book_date;
      this.form[this.rep].broker = { [this.form.broker_id]: {} };
      this.form[this.rep].effective_date = this.form[this.rep].book_date;
    },
    onChangeSalerProvTake() {
      if (!this.isSalerProvTake) {
        this.form[this.rep].saler_prov_take = 0.0;
      }
    },
    onChangeEntryDetails() {
      if (!this.isEntryDetails) {
        this.deleteAll();
        this.form[this.rep].gross = 0.0;
        this.form[this.rep].net = 0.0;
      }
    },
    deleteAll() {
      for (let d of this.dtls.filter((dtl) => dtl.selected)) {
        this.deleteCreateEntryDetail(
          this.form.book_entries.entry_details,
          d.id
        );
      }
    },
    customResetForm() {
      this.deleteAll();
      this.resetForm();
    },
    storeEntryDetails(book_entry_data) {
      let storeEntryDetails = [];
      this.form[this.rep_entrdtl].book_entry_id = book_entry_data.id;
      this.form[this.rep_entrdtl].detail_date = book_entry_data.book_date;
      Object.values(this.form.book_entries.entry_details)
        .filter((treasury) => treasury.type !== null && treasury.type !== null)
        .forEach((filteredTreasury) => {
          this.form[this.rep_entrdtl].gross = filteredTreasury.amount;
          this.form[this.rep_entrdtl].treasury_id = filteredTreasury.type;
          let trs = this.store(this.rep_entrdtl);
          storeEntryDetails.push(trs);
        });
      return storeEntryDetails;
    },
    onSuccess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Contabile Creato (II)`,
      });
      this.shortcut("module.CONT.CONP", null, null, "filterVariousAccounting");
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      // getTreasuries: "treasuries",
      getExcludedTreasuries: "excludedTreasuries",
    }),
  },
  components: {
    BaseIcon,
    BaseSelect,
    BaseDatepicker,
    BaseCurrency,
    BaseInput,
    EntryDetails,
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    entryDetailsCreateTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
  },
  beforeMount() {
    this.broker_options = this.getSalesmen();
  },
  mounted() {
    // this.initCreateEntryDetail(this.form.book_entries.entry_details);
    this.initDefaultFormValues();
  },
  created() {
    this.isLoading = true;
    const be = this.fetchCreateForm(this.repository);
    const ed = this.fetchCreateForm(this.rep_entrdtl);
    // this.onlyRealTreasuries = true;

    Promise.all([be, ed]).then(() => {
      this.isLoading = false;
    });
  },
};
</script>
